import { useState } from "react";
import { IconButton, InputAdornment, SvgIcon } from "@material-ui/core";
import EdvalLoginInputForm, { LoginInputFormProps } from "pages/commonComponents/edvalLoginInputForm";
import { ErrorMessage } from "../loginPagesCommonStyle";

type PasswordInputFormProps = LoginInputFormProps["inputProps"] &
  Pick<LoginInputFormProps, "label"> & {
    validity?: boolean;
    errorMessage?: string;
  };

function PasswordInputForm({ label, validity, errorMessage, ...props }: PasswordInputFormProps): JSX.Element {
  const [passwordVisibility, setPasswordVisibility] = useState<boolean>(false);
  const [isSelected, setIsSelected] = useState<boolean>(false);

  const handleClickShowPassword = () => {
    setPasswordVisibility(visible => !visible);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    // keep the default focus on input field
    event.preventDefault();
  };

  const visibleSVG = (
    <SvgIcon width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 0.375C7.73906 0.375 4.8 2.27813 3.08438 3.87188C1.93125 4.94531 1.21406 5.98594 0.815625 6.67031C0.290625 7.57969 0 8.40469 0 9C0 9.59531 0.290625 10.4203 0.815625 11.3297C1.21406 12.0141 1.93125 13.0547 3.08438 14.1281C4.8 15.7219 7.73906 17.625 12 17.625C19.7437 17.625 24 11.1469 24 9C24 6.85313 19.7437 0.375 12 0.375ZM19.8937 13.0078C18.3703 14.4281 15.7688 16.125 12 16.125C8.22656 16.125 5.625 14.4422 4.10625 13.0312C2.2875 11.3344 1.5 9.54375 1.5 9C1.5 8.45625 2.2875 6.66563 4.10625 4.96875C5.625 3.55781 8.22656 1.875 12 1.875C15.7688 1.875 18.3703 3.57188 19.8937 4.99219C21.8578 6.82969 22.5 8.59688 22.5 9C22.5 9.40312 21.8578 11.1703 19.8937 13.0078ZM12 15C8.69063 15 6 12.3094 6 9C6 5.69063 8.69063 3 12 3C15.3094 3 18 5.69063 18 9C18 12.3094 15.3094 15 12 15ZM12 4.5C9.52031 4.5 7.5 6.52031 7.5 9C7.5 11.4797 9.52031 13.5 12 13.5C14.4797 13.5 16.5 11.4797 16.5 9C16.5 6.52031 14.4797 4.5 12 4.5Z"
        fill="#6C717B"
      />
    </SvgIcon>
  );

  const invisibleSVG = (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.37812 4.1959C8.69531 3.70371 10.2328 3.37559 12 3.37559C19.7437 3.37559 24 9.85371 24 12.0006C24 13.1303 22.8234 15.4506 20.5875 17.4053L19.5234 16.3412C19.6547 16.2287 19.7766 16.1162 19.8937 16.0084C21.8578 14.1709 22.5 12.4037 22.5 12.0006C22.5 11.5975 21.8578 9.83027 19.8937 7.99277C18.3703 6.57246 15.7688 4.87559 12 4.87559C10.7203 4.87559 9.57656 5.06777 8.56406 5.38183L7.37812 4.1959ZM15.4359 18.6146C14.4234 18.9287 13.2797 19.1256 12 19.1256C8.22656 19.1256 5.625 17.4428 4.10625 16.0318C2.2875 14.335 1.5 12.5443 1.5 12.0006C1.5 11.4568 2.2875 9.66621 4.10625 7.96933C4.21875 7.86152 4.34063 7.75371 4.46719 7.6459L3.40312 6.58183C3.29062 6.68027 3.1875 6.77402 3.08438 6.86777C1.93125 7.94121 1.21406 8.98183 0.815625 9.66621C0.290625 10.5803 0 11.4053 0 12.0006C0 12.5959 0.290625 13.4209 0.815625 14.3303C1.21406 15.0146 1.93125 16.0553 3.08438 17.1287C4.8 18.7225 7.73438 20.6256 12 20.6256C13.7109 20.6256 15.2484 20.3115 16.6125 19.7959L15.4359 18.6146ZM10.8328 7.65527C11.2031 7.55683 11.5969 7.50058 11.9953 7.50058C14.475 7.50058 16.4953 9.5209 16.4953 12.0006C16.4953 12.4037 16.4438 12.7928 16.3406 13.1631L17.5172 14.3396C17.8219 13.6178 17.9953 12.8303 17.9953 11.9959C17.9953 8.68652 15.3047 5.9959 11.9953 5.9959C11.1656 5.9959 10.3734 6.16465 9.65156 6.47402L10.8328 7.65527ZM13.1625 16.3459C12.7922 16.4443 12.3984 16.5006 11.9953 16.5006C9.51562 16.5006 7.49531 14.4803 7.49531 12.0006C7.49531 11.5975 7.54688 11.2084 7.65 10.8334L6.47344 9.65683C6.16875 10.3787 5.99531 11.1709 5.99531 12.0006C5.99531 15.31 8.68594 18.0006 11.9953 18.0006C12.825 18.0006 13.6172 17.8318 14.3391 17.5225L13.1625 16.3459ZM23.7797 23.7803C24.0703 23.4896 24.0703 23.0115 23.7797 22.7209L1.27969 0.220898C0.989062 -0.0697266 0.510937 -0.0697266 0.220312 0.220898C-0.0703125 0.511523 -0.0703125 0.989648 0.220312 1.28027L22.7203 23.7803C22.8656 23.9256 23.0578 24.0006 23.25 24.0006C23.4422 24.0006 23.6344 23.9256 23.7797 23.7803Z"
        fill="#6C717B"
      />
    </SvgIcon>
  );

  return (
    <>
      <EdvalLoginInputForm
        label={label}
        inputProps={{
          autoComplete: "current-password",
          type: passwordVisibility ? "text" : "password",
          endAdornment: isSelected ? (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}>
                {passwordVisibility ? visibleSVG : invisibleSVG}
              </IconButton>
            </InputAdornment>
          ) : (
            <></>
          ),
          ...props,
          onBlur: event => {
            setIsSelected(false);
            if (props.onBlur !== undefined) {
              props.onBlur(event);
            }
          },
          onFocus: () => {
            setIsSelected(true);
          },
        }}
      />

      {errorMessage && !validity && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </>
  );
}

export default PasswordInputForm;
