import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import LoginPage from "pages/loginPage";
import ForgotPasswordPage from "pages/forgotPasswordPage";
import PrivateRouteToProductPage from "routers/PrivateRouteToProductPage";
import PrivateRouteRequiredToken from "routers/PrivateRouteRequiredToken";
import { ResetPasswordPage } from "pages/resetPasswordPage";
import { ConfirmAccountPage } from "pages/confirmAccountPage";
import LoginFailurePage from "pages/loginFailurePage";
import SSOLoginPage from "pages/ssoLoginPage";
import LogoutPage from "pages/logoutPage";

function App(): JSX.Element {
  return (
    <Router>
      <Switch>
        <Route path="/forgot-password" exact component={ForgotPasswordPage} />
        <Route path="/login-failed" exact component={LoginFailurePage} />
        <Route path="/logout" exact component={LogoutPage} />
        <Route path="/sso-login" exact component={SSOLoginPage} />
        <PrivateRouteRequiredToken path="/reset-password" component={ResetPasswordPage} />
        <PrivateRouteRequiredToken path="/confirm-account" component={ConfirmAccountPage} />
        <PrivateRouteToProductPage path="/products" />
        <Route path="/" component={LoginPage} />
      </Switch>
    </Router>
  );
}

export default App;
