import { login, sSOLogin } from "apis";
import { ApiLoginErrorType } from "apis/typescript_api_client";
import { useHistory } from "react-router-dom";
import { handleApiError } from "utils/apiErrorHandling";

export type Login = { email: string; password: string };
export async function loginUser(loginInfo: Login): Promise<void> {
  try {
    const { email, password } = loginInfo;
    const { status, data } = await login({ username: email, password });

    if (status === 200) {
    } else {
      throw data;
    }
  } catch (e) {
    handleApiError(e, "Failed to log in.");
  }
}

export async function ssoLoginUser(cognitoAuthCode: string, subdomain: string): Promise<void> {
  const history = useHistory();
  try {
    const { status, data } = await sSOLogin({ AuthorizationCode: cognitoAuthCode });

    if (status === 200) {
      if (data.ErrorType === ApiLoginErrorType.UserDoesNotExist) {
        // If user is not found, redirect to error page
        history.push("/login-failed?errorType=user_does_not_exist");
      } else if (data.ErrorType === ApiLoginErrorType.NoAuthCode) {
        // If no auth code was given, redirect to error page
        history.push("/login-failed?errorType=no_auth");
      }
    } else {
      throw data;
    }
  } catch (e) {
    handleApiError(e, "Failed to log in.");
  }
}
