import { AxiosPromise } from "axios";
import { handleApiError } from "utils/apiErrorHandling";

export async function resetPassword(request: () => AxiosPromise<void>, failMessage: string): Promise<void> {
  try {
    const { status, data } = await request();
    if (status !== 200) {
      throw data;
    }
  } catch (e) {
    handleApiError(e, failMessage);
  }
}
