import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { LargeBoldText } from "pages/commonComponents/loginPagesCommonStyle";

export function PasswordResetSuccessful({ message }: { message: string }): JSX.Element {
  const history = useHistory();
  const [timeRemain, setTimeRemain] = useState<number>(10);

  useEffect(() => {
    let countDown: NodeJS.Timeout;
    if (timeRemain > -1) {
      countDown = setInterval(() => {
        setTimeRemain(timeRemain - 1);
      }, 1000);
    } else {
      history.push("/");
    }

    return () => clearInterval(countDown);
  });

  return (
    <LargeBoldText>
      {message} You are being redirected to the login page in {timeRemain > 0 ? timeRemain : 0} s.
    </LargeBoldText>
  );
}
