import { useState } from "react";
import { validateLoginEmail } from "pages/commonComponents/emailInputForm/validateLoginEmail";
import { CustomInputEvent } from "../eventTypes";
import { forgotPassword } from "./forgotPassword";
import { EdvalLoginPageFrame, EmailInputForm, EdvalSubmitButtonWithLoadingStatus } from "../commonComponents";
import { BoldLink, ErrorMessage, LargeBoldText } from "../commonComponents/loginPagesCommonStyle";

function ForgotPasswordPage(): JSX.Element {
  const [email, setEmail] = useState<string>("");
  const [emailValidity, setEmailValidity] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [emailSent, setEmailSent] = useState<boolean>(false);

  const emailOnChange = (e: CustomInputEvent) => {
    setErrorMessage("");
    setEmail(e.target.value);
    setEmailValidity(true);
  };

  const validateEmail = () => {
    const validity = validateLoginEmail(email);
    setEmailValidity(validity);
    setErrorMessage("");

    return validity;
  };

  const buttonIsDisabled = !email || !emailValidity || Boolean(errorMessage);

  const onSubmit = async () => {
    if (!validateEmail()) {
      return;
    }

    try {
      await forgotPassword(email);
      setEmailSent(true);
    } catch (errorMessage) {
      setErrorMessage(errorMessage as string);
    }
  };

  return (
    <EdvalLoginPageFrame greeting="Recover your password">
      {emailSent ? (
        <>
          <p color="#212937">If account exists, an email will be sent with further instructions.</p>
          <br />
          <br />
          <BoldLink to="/">Back to sign in page</BoldLink>
        </>
      ) : (
        <>
          <form
            onSubmit={event => {
              event.preventDefault();
            }}>
            <EmailInputForm
              label="Email"
              value={email}
              onChange={emailOnChange}
              onBlur={validateEmail}
              validity={emailValidity}
              errorMessage="Please enter email address in format: name@example.com"
            />
          </form>
          <BoldLink id="back-to-sign-in" to="/">
            Back to sign in page
          </BoldLink>
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
          <EdvalSubmitButtonWithLoadingStatus disabled={buttonIsDisabled} onClick={onSubmit}>
            send link
          </EdvalSubmitButtonWithLoadingStatus>
        </>
      )}
    </EdvalLoginPageFrame>
  );
}

export default ForgotPasswordPage;
