import styled from "styled-components/macro";
import { ReactComponent as Logo } from "assets/tes_logo_powered_by_edval.svg";

export const Background = styled.div`
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Wrapper = styled.div`
  width: 630px;
  height: 600px;
  margin-top: 80px;
  margin-bottom: 80px;
  border-radius: 40px;
  border: 5px solid #212937;
  background-color: #fff;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  width: 528px;
  display: flex;
  flex-direction: column;
`;

export const Greeting = styled.h4`
  ${({ theme }) => `color: ${theme.palette.primary.main};`}
  font-size: 23px;
  margin-top: 48px;
  margin-bottom: 30px;
  line-height: 27px;
`;

export const TesLogo = styled(Logo)`
  width: 528px;
  height: 94px;
  align: center;
`;
