import { startResetPassword } from "apis";
import { handleApiError } from "utils/apiErrorHandling";

export async function forgotPassword(email: string): Promise<void> {
  try {
    const { status, data } = await startResetPassword({ email });

    if (status !== 200) {
      throw data;
    }
  } catch (e) {
    handleApiError(e, "Failed to submit email.");
  }
}
