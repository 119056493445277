import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createMuiTheme, MuiThemeProvider, StylesProvider } from "@material-ui/core";
import { ThemeProvider } from "styled-components";
import "url-polyfill"; // to support URL and URLSearchParams in IE

const edvalTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#212937",
      dark: "rgba(17, 42, 77, 1)",
    },
    action: { disabled: "rgba(62, 62, 62, 1)" }, // used in PasswordInputForm,
    secondary: {
      main: "rgba(43, 135, 50, 1)",
    },
  },
});

ReactDOM.render(
  <StylesProvider injectFirst>
    <MuiThemeProvider theme={edvalTheme}>
      <ThemeProvider theme={edvalTheme}>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </ThemeProvider>
    </MuiThemeProvider>
  </StylesProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
