import { RouteProps } from "react-router-dom";
import PrivateRouter from "./PrivateRouter";
import ProductsPage from "pages/productPage";
import isLoggedIn from "utils/isLoggedIn";

export default function PrivateRouteToProductPage({ ...rest }: RouteProps): JSX.Element {
  const loggedIn = isLoggedIn();

  return (
    <PrivateRouter accessCondition={loggedIn} {...rest}>
      <ProductsPage />
    </PrivateRouter>
  );
}
