import { useEffect, useState } from "react";
import { ProductListWrapper, Header, EdvalToolbar, ProductGrid, SignOutButton } from "./style";
import { ReactComponent as TesLogo } from "assets/logo_tes.svg";
import EdvalProductCard from "./edvalProductCard";
import { getUserProducts, Product, signOut } from "apis/index";
import { useHistory } from "react-router-dom";
import Logo from "./Logo";
import { Configuration, SignOutApi } from "apis/typescript_api_client";
import axios from "axios";

const ProductsPage = (): JSX.Element => {
  const [products, setProducts] = useState<Product[]>([]);
  const history = useHistory();
  useEffect(() => {
    initialise();
  }, []);

  async function initialise(): Promise<void> {
    const { status, data } = await getUserProducts();
    if (status !== 200) {
      console.warn(data);
      history.push("/");
    } else {
      setProducts(data as Product[]);
    }
  }

  async function signOutClicked(): Promise<void> {
    history.push("/logout");
  }

  return (
    <div>
      <EdvalToolbar disableGutters>
        <Logo />
        <SignOutButton onClick={signOutClicked}>Sign out</SignOutButton>
      </EdvalToolbar>
      <ProductListWrapper>
        <Header>Your Modules</Header>
        {products.length > 0 && (
          <ProductGrid>
            {products.map(product => (
              <EdvalProductCard product={product} key={product.Id} />
            ))}
          </ProductGrid>
        )}
      </ProductListWrapper>
    </div>
  );
};

export default ProductsPage;
