import { Link } from "@material-ui/core";
import { Product } from "apis";
import { ReactComponent as LockIcon } from "./lock_black_24dp.svg";
import {
  Description,
  LockIconWrapper,
  ProductButton,
  ProductCard,
  TextLink,
  TopCardDiv,
  Name,
  TextContainer,
  BottomCardDiv,
  ColorBar,
  DisabledCardText,
} from "./style";

type ProductCardProps = {
  product: Product;
};

const EdvalProductCard = ({ product }: ProductCardProps): JSX.Element => {
  const {
    LogoUrl: logoUrl,
    Url: url,
    Description: description,
    AccessibleByUser: accessibleByUser,
    Name: name,
    HighlightColor: highlightColor,
  } = product;

  const unavailableLogoUrl = logoUrl?.split(".").slice(0, -1).join(".") + "-disabled.svg" ?? logoUrl;

  return accessibleByUser ? (
    <ProductCard id="product-card">
      <ColorBar id="color-bar" style={{ backgroundColor: highlightColor ?? "black" }} />
      <TopCardDiv>
        <img id="product-image" src={logoUrl} width="54px" />

        <TextContainer>
          <Name>{name}</Name>
          <Description>{description}</Description>
        </TextContainer>
      </TopCardDiv>

      <BottomCardDiv>
        <ProductButton id="product-button" variant="text" href={url}>
          Open
        </ProductButton>
      </BottomCardDiv>
    </ProductCard>
  ) : (
    <ProductCard id="product-card-disabled">
      <ColorBar id="color-bar" style={{ backgroundColor: "#6C717B" }} />
      <TopCardDiv>
        <img id="product-image" src={unavailableLogoUrl} width="54px" />

        <TextContainer>
          <Name style={{ color: "#6C717B" }}>{name}</Name>
          <Description style={{ color: "#6C717B" }}>{description}</Description>
        </TextContainer>
      </TopCardDiv>

      <BottomCardDiv>
        <DisabledCardText id="disabled-card-text">
          Talk to your school administrator if you’d like to gain access to the {name} module.
        </DisabledCardText>
      </BottomCardDiv>
    </ProductCard>
  );
};

export default EdvalProductCard;
