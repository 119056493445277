import { useEffect, useState } from "react";
import { useRedirect } from "hooks/useRedirect";
import { EdvalLoginPageFrame } from "../commonComponents";
import { BottomErrorMessage } from "../commonComponents/loginPagesCommonStyle";
import Spinner from "react-spinner-material";
import { ssoLoginUser } from "pages/loginPage/userLogin";
import assert from "assert";
import { useHistory } from "react-router-dom";
import { getLoginSubdomain } from "utils/ssoHelpers";

function SSOLoginPage(): JSX.Element {
  const [isLoading, setIsLoading] = useState(true);

  const redirect = useRedirect();
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {
    const ssoLogin = async () => {
      // Obtain cognito access token from query param
      const urlParams = new URLSearchParams(window.location.search);
      const authorizationCode = urlParams.get("code");
      const error = urlParams.get("error");
      if (error !== null) {
        // Something went wrong logging in via SSO
        const errorDescription = urlParams.get("error_description");
        let failureUrl = `/login-failed?errorType=${error}`;
        if (errorDescription !== null) {
          failureUrl += `&errorDescription=${errorDescription}`;
        }
        history.push(failureUrl);
      } else {
        try {
          if (authorizationCode !== null) {
            const subdomain = getLoginSubdomain();
            assert(subdomain !== undefined, "A subdomain should exist for this url!");
            // Auth code retrived from cognito successfully,
            // start login process (convert code into token and use that to login)
            await ssoLoginUser(authorizationCode, subdomain);
            setIsLoading(false);
            redirect("redirect_uri", "/products");
          } else {
            // Auth code not retrieved from cognito for some reason
            history.push("/login-failed?errorType=no_auth");
          }
        } catch (errorMessage) {
          setErrorMessage(errorMessage as string);
        }
      }
    };

    ssoLogin();
  }, []);

  return isLoading ? (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "inherit" }}>
      <Spinner style={{ margin: "auto" }} radius={150} color={"#515af2"} stroke={6} visible={true} />
    </div>
  ) : (
    <EdvalLoginPageFrame greeting="">
      {errorMessage && <BottomErrorMessage id="login-failed-message">{errorMessage}</BottomErrorMessage>}
    </EdvalLoginPageFrame>
  );
}

export default SSOLoginPage;
