import { Button, Link } from "@material-ui/core";
import styled from "styled-components";

export const ProductButton = styled(Button)`
  margin-top: 16px;
  width: 57px;
  height: 25px;
  background-color: white;
  border: 1px solid #212937;
  border-radius: 4px;
  text-transform: none;
  font-weight: 700;
  font-family: 'Arial'
  color: #212937;
  img {
    max-width: 100%;
  }
  :disabled {
    background-color: gray;
    img {
      opacity: 0.5;
    }
  }
`;

export const Description = styled.p`
  width: 345px;
  font-size: 20px;
  margin: 16px 0px;
  font-family: "Arial";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  &.dimmed {
    color: rgba(155, 155, 155, 1);
  }
`;

export const LockIconWrapper = styled.span`
  margin-right: 8px;
`;

export const TextLink = styled(Link)`
  font-size: 18px;
`;

export const ProductCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 416px;
  min-height: 196px;
  padding: 16px;
  justify-content: space-between;
  background: #ffffff;
  overflow: clip;

  outline: 1px solid #bdc2cd;
  border-radius: 4px;
  position: relative;
`;

export const TopCardDiv = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  align-items: flex-start;
`;

export const BottomCardDiv = styled.div`
  display: flex;
  flex-grow: 0;
  border-top: 1px solid #bdc2cd;
`;

export const Name = styled.p`
  font-family: "Noto Sans", sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  color: #171d27;
`;

export const TextContainer = styled.div`
  padding-left: 16px;
`;

export const ColorBar = styled.div`
  padding: 0;
  margin: 0;
  width: 150%;
  position: relative;

  //Offsetting the padding of the parent
  top: -16px;
  left: -16px;

  background-color: black;
  height: 4px;
`;

export const DisabledCardText = styled.p`
  color: #6c717b;
  margin: 9px 0 0 0;
  font-family: "Arial";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
`;
