import { useEffect, useState, useRef } from "react";
import { ButtonProps, ButtonTypeMap } from "@material-ui/core";
import Spinner from "react-spinner-material";
import { Button } from "./style";

type LoginButtonProps = ButtonProps<ButtonTypeMap["defaultComponent"], { onClick: () => Promise<void> }>;

function EdvalSubmitButton({ children, onClick, disabled, ...props }: LoginButtonProps): JSX.Element {
  const [loading, setLoading] = useState<boolean>(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const onClickWithLoadingStatus = async () => {
    if (!loading) {
      setLoading(true);
    }
    await onClick();
    setLoading(false);
  };

  useEffect(() => {
    const handleEnterKeyUp = (event: KeyboardEvent) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        buttonRef?.current?.click();
      }
    };

    window.addEventListener("keyup", handleEnterKeyUp);

    return () => window.removeEventListener("keyup", handleEnterKeyUp);
  }, []);

  return (
    <Button
      ref={buttonRef}
      variant="contained"
      color="secondary"
      onClick={onClickWithLoadingStatus}
      disabled={disabled || loading}
      {...props}>
      {loading ? <Spinner radius={27} color={"#000"} stroke={3} visible={true} /> : children}
    </Button>
  );
}

export default EdvalSubmitButton;
