import { useHistory, useLocation } from "react-router-dom";
import { getDomainName } from "../utils/getDomainName";
import { getHostname } from "../utils/getHostname";

export function useRedirect(): (queryUriString: string, fallbackUrl: string) => void {
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);

  return (queryUriString: string, fallbackUrl: string): void => {
    const redirectUri = query.get(queryUriString);
    if (redirectUri && getDomainName(getHostname(redirectUri)) === getDomainName()) {
      window.location.assign(redirectUri);
    } else {
      history.push(fallbackUrl);
    }
  };
}
