import EdvalLoginInputForm, { LoginInputFormProps } from "pages/commonComponents/edvalLoginInputForm";
import { ErrorMessage } from "../loginPagesCommonStyle";

type EmailInputFormProps = LoginInputFormProps["inputProps"] &
  Pick<LoginInputFormProps, "label"> & {
    validity?: boolean;
    errorMessage?: string;
  };

function EmailInputForm({ label, validity, errorMessage, ...props }: EmailInputFormProps): JSX.Element {
  const MAX_CHARACTER = 256;

  return (
    <>
      <EdvalLoginInputForm
        label={label}
        inputProps={{
          inputProps: { maxLength: MAX_CHARACTER },
          autoComplete: "username",
          ...props,
        }}
      />

      {errorMessage && !validity && <ErrorMessage id="email-error">{errorMessage}</ErrorMessage>}
    </>
  );
}

export default EmailInputForm;
