import { Configuration } from "./typescript_api_client";

const BASE_PATH = process.env.REACT_APP_BASE_PATH || "";

if (process.env.NODE_ENV === "development") {
  //BASE_PATH = "http://localhost:5000";
}

export default new Configuration({
  basePath: BASE_PATH,
  baseOptions: {
    validateStatus: () => true,
    withCredentials: true,
    headers: {
      Accept: "application/json",
    },
  },
});
