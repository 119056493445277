import { useEffect, useState } from "react";
import { EdvalLoginPageFrame } from "../commonComponents";
import { useHistory } from "react-router-dom";
import { getLoginSubdomain, redirectToSSOForSubdomain } from "utils/ssoHelpers";
import { StyledMuiLink } from "pages/commonComponents/loginPagesCommonStyle";
import Spinner from "react-spinner-material";
import { signOut } from "apis";

function LogoutPage(): JSX.Element {
  const history = useHistory();
  const REDIRECT_SECONDS = 5;
  const [secondsToRedirect, setSecondsToRedirect] = useState(REDIRECT_SECONDS);
  const [signedOut, setSignedOut] = useState(false);

  const redirect = async () => {
    // Obtain subdomain from page url, we will use this to know whether
    // to redirect to SSO login page associated with subdomain, or regular login page
    const subdomain = getLoginSubdomain();
    if (subdomain === undefined) {
      history.push("/");
    } else {
      await redirectToSSOForSubdomain(subdomain);
    }
  };

  useEffect(() => {
    const logout = async () => {
      await signOut();
      setSignedOut(true);
    };

    logout();
  }, []);

  useEffect(() => {
    if (signedOut) {
      const interval = setInterval(() => {
        if (secondsToRedirect > 1) {
          setSecondsToRedirect(secondsToRedirect - 1);
        } else {
          redirect();
        }
      }, 1000);

      return () => clearInterval(interval);
    } else {
      setSecondsToRedirect(REDIRECT_SECONDS);
    }

    return;
  }, [secondsToRedirect, signedOut]);

  return !signedOut ? (
    <EdvalLoginPageFrame greeting="Logging you out">
      <p>Hold on while we log you out</p>
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "inherit" }}>
        <Spinner style={{ margin: "auto" }} radius={150} color={"#515af2"} stroke={6} visible={true} />
      </div>
    </EdvalLoginPageFrame>
  ) : (
    <EdvalLoginPageFrame greeting="Logout successful">
      <p>
        Redirecting to login in {secondsToRedirect} second{secondsToRedirect > 1 ? "s" : ""}...
      </p>
      <p>
        Click{" "}
        <StyledMuiLink id="force-redirect" onClick={redirect}>
          here
        </StyledMuiLink>{" "}
        if you are not redirected in {REDIRECT_SECONDS} seconds...
      </p>
    </EdvalLoginPageFrame>
  );
}

export default LogoutPage;
