import styled, { css } from "styled-components/macro";
import { Link } from "react-router-dom";

import { Link as MuiLink } from "@material-ui/core/";

const baseLinkCss = css`
  color: var(--main-text-colour);
  text-decoration: underline;
  font-size: 14px;
`;

export const BoldLink = styled(Link)`
  ${baseLinkCss}
  margin-top: 6px;
  margin-bottom: 20px;
  font-weight: bold;
  flex-align: start;
  color: #6c717b;
`;

export const StyledMuiLink = styled(MuiLink)`
  color: var(--main-text-colour);
  text-decoration: underline;
  margin-top: 6px;
  margin-bottom: 20px;
  font-weight: bold;
  flex-align: start;
  color: #6c717b;
  cursor: pointer;
`;

export const ExternalBoldLink = styled.a`
  ${baseLinkCss}
  margin-top: 27px;
  font-weight: bold;
  align-self: flex-end;
`;

export const AbsolutePositionedLink = styled(Link)`
  ${baseLinkCss}
  margin-top: 27px;
  color: #6c717b;
  font-weight: bold;
  align-self: flex-end;
`;

export const RelativeWrapper = styled.div`
  position: relative;
`;

export const ErrorMessage = styled.div`
  font-size: 15px;
  line-height: 18px;
  color: rgba(232, 37, 65, 1);
  margin-bottom: 16px;
`;

export const BottomErrorMessage = styled.div`
  font-size: 15px;
  line-height: 18px;
  color: rgba(232, 37, 65, 1);
  margin-top: 6px;
  margin-bottom: -16px;
`;

export const LargeBoldText = styled.p`
  color: var(--main-text-colour);
  font-size: 19px;
  line-height: 22px;
  margin: 0;
  font-weight: bold;
`;

export const LoginFailureText = styled.p`
  color: var(--main-text-colour);
  font-size: 18px;
  line-height: 22px;
  margin: 0;
  margin-bottom: 6px;
`;
