import { RouteProps, Route, Redirect } from "react-router-dom";

export default function PrivateRouter({
  accessCondition,
  children,
  ...rest
}: { accessCondition: boolean } & RouteProps): JSX.Element {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        accessCondition ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
