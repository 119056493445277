import styled from "styled-components/macro";
import { InputBase, InputLabel, FormControl as MuiFormControl, TextField } from "@material-ui/core";

export const FormControl = styled(MuiFormControl)`
  margin-bottom: 14px;
`;

export const Label = styled(InputLabel)`
  ${({ theme }) => `color: ${theme.palette.primary.dark};`}
  font-size: 19px;
  line-height: 22px;
  font-weight: bold;
`;

export const EdvalTextField = styled(TextField)`
  width: 100%;
  margin-bottom: 14px;
`;

export const Input = styled(InputBase)`
  width: 100%;
  margin-top: 30px;
  margin-bottom: 6px;
  background-color: rgba(228, 228, 228, 1); /* TODO can theme it later if commonly used */
  border-radius: 5px;

  & .MuiInputBase-input {
    position: relative;
    font-size: 18px;
    line-height: 21px;
    height: 20px;
    padding: 10px 12px;
  }
`;
