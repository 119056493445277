import { useEffect, useState } from "react";
import { useRedirect } from "hooks/useRedirect";
import { validatePasswordLength } from "pages/loginPage/validatePasswordLength";
import { CustomInputEvent } from "../eventTypes";
import { Login, loginUser } from "./userLogin";
import {
  EdvalLoginPageFrame,
  EmailInputForm,
  PasswordInputForm,
  EdvalSubmitButtonWithLoadingStatus,
} from "../commonComponents";
import {
  AbsolutePositionedLink,
  RelativeWrapper,
  BottomErrorMessage,
} from "../commonComponents/loginPagesCommonStyle";
import { validateLoginEmail } from "pages/commonComponents/emailInputForm/validateLoginEmail";
import Spinner from "react-spinner-material";
import { getLoginSubdomain, redirectToSSOForSubdomain } from "utils/ssoHelpers";

function LoginPage(): JSX.Element {
  const [isLoading, setIsLoading] = useState(true);
  const redirect = useRedirect();
  useEffect(() => {
    const checkSubdomainRedirect = async () => {
      const subdomain = getLoginSubdomain();

      let disableLoadingSpinner = true;
      if (subdomain !== undefined) {
        // we only disable the loading spinner if the user is not being redirected.
        disableLoadingSpinner = !(await redirectToSSOForSubdomain(subdomain));
      }

      if (disableLoadingSpinner) {
        setIsLoading(false);
      }
    };

    checkSubdomainRedirect();
  }, []);

  const [loginInput, setLoginInput] = useState<Login>({
    email: "",
    password: "",
  });
  const [emailValidity, setEmailValidity] = useState<boolean>(true);
  const [passwordValidity, setPasswordValidity] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const onInputChange = (type: keyof Login) => (value: string) => {
    setLoginInput(values => ({ ...values, [type]: value }));
  };

  const emailOnChange = ({ target: { value } }: CustomInputEvent) => {
    onInputChange("email")(value);
    setErrorMessage("");
    setEmailValidity(true);
  };

  const validateEmail = () => {
    const validity = validateLoginEmail(loginInput.email);
    setEmailValidity(validity);
    setErrorMessage("");

    return validity;
  };

  const passwordOnChange = ({ target: { value } }: CustomInputEvent) => {
    onInputChange("password")(value);
    setErrorMessage("");
    setPasswordValidity(validatePasswordLength(value));
  };

  const validatePassword = () => {
    const validity = validatePasswordLength(loginInput.password);
    setPasswordValidity(validity);
    setErrorMessage("");

    return validity;
  };

  const buttonIsDisabled =
    !loginInput.email || !loginInput.password || !emailValidity || !passwordValidity || Boolean(errorMessage);

  const onSubmit = async () => {
    if (!validateEmail() || !validatePassword()) {
      return;
    }

    try {
      await loginUser(loginInput);
      redirect("redirect_uri", "/products");
    } catch (errorMessage) {
      setErrorMessage(errorMessage as string);
      onInputChange("password")("");
    }
  };

  return isLoading ? (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "inherit" }}>
      <Spinner style={{ margin: "auto" }} radius={150} color={"#515af2"} stroke={6} visible={true} />
    </div>
  ) : (
    <EdvalLoginPageFrame greeting="">
      <form>
        <EmailInputForm
          label="Email"
          value={loginInput.email}
          onChange={emailOnChange}
          onBlur={validateEmail}
          validity={emailValidity}
          errorMessage="Please enter email address in format: name@example.com"
        />

        <RelativeWrapper>
          <PasswordInputForm
            label="Password"
            value={loginInput.password}
            onChange={passwordOnChange}
            onBlur={validatePassword}
          />
          <AbsolutePositionedLink id="forgot-password" to="forgot-password">
            Forgot password?
          </AbsolutePositionedLink>
        </RelativeWrapper>
      </form>

      {errorMessage && <BottomErrorMessage id="login-failed-message">{errorMessage}</BottomErrorMessage>}
      <EdvalSubmitButtonWithLoadingStatus id="sign-in" disabled={buttonIsDisabled} onClick={onSubmit}>
        sign in
      </EdvalSubmitButtonWithLoadingStatus>
    </EdvalLoginPageFrame>
  );
}

export default LoginPage;
