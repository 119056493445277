import { InputProps, InputLabelProps, FormControlTypeMap } from "@material-ui/core";
import { EdvalTextField } from "./style";

export type LoginInputFormProps = {
  label: string;
  formProps?: FormControlTypeMap; // it's not applied in component
  labelProps?: InputLabelProps; // it's not applied in component
  inputProps: InputProps & {
    onChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    value: string;
  };
};

function EdvalLoginInputForm({ label, inputProps }: LoginInputFormProps): JSX.Element {
  return (
    <EdvalTextField
      id={label}
      variant="outlined"
      label={label}
      onChange={inputProps.onChange}
      color="primary"
      autoComplete={inputProps.autoComplete}
      type={inputProps.type}
      InputProps={inputProps}></EdvalTextField>
  );
}

export default EdvalLoginInputForm;
