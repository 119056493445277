import { useState } from "react";
import { validatePasswordSecurity } from "pages/commonComponents/resetPasswordForm/validatePasswordSecurity";
import { EdvalLoginPageFrame, PasswordInputForm, EdvalSubmitButtonWithLoadingStatus } from "..";
import { samePasswords } from "./samePasswords";
import { CustomInputEvent } from "../../eventTypes";
import { ErrorMessage } from "pages/commonComponents/loginPagesCommonStyle";
import { resetPassword } from "./resetPassword";
import { PasswordResetSuccessful } from "./passwordResetSuccessful";
import { AxiosPromise } from "axios";

type Passwords = {
  newPassword: string;
  confirmPassword: string;
};

type Props = {
  greeting: string;
  passwordLabel: string;
  confirmPasswordLabel: string;
  buttonText: string;
  successMessage: string;
  failMessage: string;
  setPassword: (password: string) => () => AxiosPromise<void>;
};

function ResetPasswordForm({
  greeting,
  passwordLabel,
  confirmPasswordLabel,
  buttonText,
  successMessage,
  failMessage,
  setPassword,
}: Props): JSX.Element {
  const [passwords, setPasswords] = useState<Passwords>({
    newPassword: "",
    confirmPassword: "",
  });
  const [newPasswordValidity, setNewPasswordValidity] = useState<boolean>(false);
  const [confirmPasswordValidity, setConfirmPasswordValidity] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [passwordUpdated, setPasswordUpdated] = useState<boolean>(false);

  const resetMessages = () => {
    setErrorMessage("");
    setPasswordUpdated(false);
  };

  const onInputChange = (type: keyof Passwords) => (value: string) => {
    setPasswords(state => ({ ...state, [type]: value }));
  };

  const newPasswordOnChange = ({ target: { value } }: CustomInputEvent) => {
    onInputChange("newPassword")(value);
    const validity = validatePasswordSecurity(value);
    setNewPasswordValidity(validity);
    resetMessages();
  };

  const validateNewPassword = () => {
    const validity = validatePasswordSecurity(passwords.newPassword);
    setNewPasswordValidity(validity);
    resetMessages();

    return validity;
  };

  const confirmPasswordOnChange = ({ target: { value } }: CustomInputEvent) => {
    onInputChange("confirmPassword")(value);
    const validity = samePasswords(passwords.newPassword, value);
    setConfirmPasswordValidity(validity);
    resetMessages();
  };

  const validateConfirmPassword = () => {
    const validity = samePasswords(passwords.newPassword, passwords.confirmPassword);
    setConfirmPasswordValidity(validity);
    resetMessages();

    return validity;
  };

  const buttonIsDisabled =
    !passwords.newPassword ||
    !passwords.confirmPassword ||
    !newPasswordValidity ||
    !confirmPasswordValidity ||
    Boolean(errorMessage);

  const onSubmit = async () => {
    if (!validateNewPassword() || !validateConfirmPassword()) {
      return;
    }

    try {
      await resetPassword(setPassword(passwords.confirmPassword), failMessage);
      setPasswordUpdated(true);
    } catch (errorMessage) {
      onInputChange("confirmPassword")("");
      setErrorMessage(errorMessage as string);
    }
  };

  return (
    <EdvalLoginPageFrame greeting={greeting}>
      {passwordUpdated ? (
        <PasswordResetSuccessful message={successMessage} />
      ) : (
        <form>
          {/* this hidden input is to resolve chrome warning */}
          <input autoComplete="email" name="username" hidden />
          <PasswordInputForm
            label={passwordLabel}
            value={passwords.newPassword}
            onChange={newPasswordOnChange}
            onBlur={validateNewPassword}
            autoComplete="new-password"
          />

          <PasswordInputForm
            label={confirmPasswordLabel}
            value={passwords.confirmPassword}
            validity={
              passwords.newPassword.length === 0 ||
              (passwords.confirmPassword.length === 0 && newPasswordValidity) ||
              (confirmPasswordValidity && newPasswordValidity)
            }
            errorMessage={
              newPasswordValidity ? "Passwords do not match." : "Password must be between 8 and 64 characters"
            }
            onChange={confirmPasswordOnChange}
            onBlur={validateConfirmPassword}
            autoComplete="new-password"
          />
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
          <EdvalSubmitButtonWithLoadingStatus onClick={onSubmit} disabled={buttonIsDisabled}>
            {buttonText}
          </EdvalSubmitButtonWithLoadingStatus>
        </form>
      )}
    </EdvalLoginPageFrame>
  );
}

export default ResetPasswordForm;
