type ApiError = {
  error?: string;
};

export function handleApiError(e: unknown, genericErrorMessage: string) {
  const castError = e as ApiError;
  if (typeof castError !== "object" || !castError.error) {
    throw genericErrorMessage;
  } else {
    throw castError.error;
  }
}
