/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Edval.OS.Login API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import { default as EdvalStaticAxios, AxiosPromise, AxiosInstance, AxiosResponse, AxiosError } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ApiCognitoAuthorizationCode
 */
export interface ApiCognitoAuthorizationCode {
    /**
     * 
     * @type {string}
     * @memberof ApiCognitoAuthorizationCode
     */
    AuthorizationCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiCognitoAuthorizationCode
     */
    SSOSubdomain?: string;
}
/**
 * 
 * @export
 * @interface ApiConfirmAccountRequest
 */
export interface ApiConfirmAccountRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiConfirmAccountRequest
     */
    token: string;
    /**
     * 
     * @type {string}
     * @memberof ApiConfirmAccountRequest
     */
    password: string;
}
/**
 * 
 * @export
 * @interface ApiHealthStatus
 */
export interface ApiHealthStatus {
    /**
     * 
     * @type {Array<ApiHealthStatusItem>}
     * @memberof ApiHealthStatus
     */
    Status?: Array<ApiHealthStatusItem>;
}
/**
 * 
 * @export
 * @interface ApiHealthStatusItem
 */
export interface ApiHealthStatusItem {
    /**
     * 
     * @type {string}
     * @memberof ApiHealthStatusItem
     */
    ServiceName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiHealthStatusItem
     */
    Description?: string;
    /**
     * 
     * @type {HealthStatusLevel}
     * @memberof ApiHealthStatusItem
     */
    Level?: HealthStatusLevel;
}
/**
 * 
 * @export
 * @interface ApiJWK
 */
export interface ApiJWK {
    /**
     * 
     * @type {string}
     * @memberof ApiJWK
     */
    alg?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiJWK
     */
    kty?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiJWK
     */
    use?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiJWK
     */
    kid?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiJWK
     */
    e?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiJWK
     */
    n?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiJWK
     */
    comment?: string;
}
/**
 * 
 * @export
 * @interface ApiJWKs
 */
export interface ApiJWKs {
    /**
     * 
     * @type {Array<ApiJWK>}
     * @memberof ApiJWKs
     */
    keys?: Array<ApiJWK>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ApiLoginErrorType {
    GeneralError = 'GeneralError',
    UserDoesNotExist = 'UserDoesNotExist',
    NoAuthCode = 'NoAuthCode'
}

/**
 * 
 * @export
 * @interface ApiLoginRequest
 */
export interface ApiLoginRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiLoginRequest
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiLoginRequest
     */
    password?: string;
}
/**
 * 
 * @export
 * @interface ApiLoginResponse
 */
export interface ApiLoginResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ApiLoginResponse
     */
    Success?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiLoginResponse
     */
    LoginFailAdvice?: string;
    /**
     * 
     * @type {ApiLoginErrorType}
     * @memberof ApiLoginResponse
     */
    ErrorType?: ApiLoginErrorType;
    /**
     * 
     * @type {string}
     * @memberof ApiLoginResponse
     */
    tenantId?: string;
}
/**
 * 
 * @export
 * @interface ApiProduct
 */
export interface ApiProduct {
    /**
     * 
     * @type {string}
     * @memberof ApiProduct
     */
    Id?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiProduct
     */
    Key: string;
    /**
     * 
     * @type {string}
     * @memberof ApiProduct
     */
    Name: string;
    /**
     * 
     * @type {string}
     * @memberof ApiProduct
     */
    Description: string;
    /**
     * 
     * @type {string}
     * @memberof ApiProduct
     */
    Url: string;
    /**
     * 
     * @type {string}
     * @memberof ApiProduct
     */
    LogoUrl: string;
    /**
     * 
     * @type {string}
     * @memberof ApiProduct
     */
    UnavailableLogoUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiProduct
     */
    HighlightColor: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApiProduct
     */
    InternalOnly: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApiProduct
     */
    Released: boolean;
}
/**
 * 
 * @export
 * @interface ApiResetPasswordRequest
 */
export interface ApiResetPasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiResetPasswordRequest
     */
    token?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiResetPasswordRequest
     */
    newPassword?: string;
}
/**
 * 
 * @export
 * @interface ApiSSORedirect
 */
export interface ApiSSORedirect {
    /**
     * 
     * @type {string}
     * @memberof ApiSSORedirect
     */
    TenantId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApiSSORedirect
     */
    SSOEnabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiSSORedirect
     */
    SSORedirectUrl?: string;
}
/**
 * 
 * @export
 * @interface ApiStartResetPasswordRequest
 */
export interface ApiStartResetPasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiStartResetPasswordRequest
     */
    email?: string;
}
/**
 * 
 * @export
 * @interface ApiUserProduct
 */
export interface ApiUserProduct {
    /**
     * 
     * @type {string}
     * @memberof ApiUserProduct
     */
    Id?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiUserProduct
     */
    Name?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiUserProduct
     */
    Key?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiUserProduct
     */
    Description?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiUserProduct
     */
    LogoUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiUserProduct
     */
    UnavailableLogoUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiUserProduct
     */
    Url?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApiUserProduct
     */
    AccessibleByUser?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiUserProduct
     */
    HighlightColor?: string;
}
/**
 * 
 * @export
 * @interface GitVersionConfiguration
 */
export interface GitVersionConfiguration {
    /**
     * 
     * @type {string}
     * @memberof GitVersionConfiguration
     */
    SemVer?: string;
    /**
     * 
     * @type {string}
     * @memberof GitVersionConfiguration
     */
    BranchName?: string;
    /**
     * 
     * @type {string}
     * @memberof GitVersionConfiguration
     */
    Sha?: string;
    /**
     * 
     * @type {string}
     * @memberof GitVersionConfiguration
     */
    ShortSha?: string;
    /**
     * 
     * @type {string}
     * @memberof GitVersionConfiguration
     */
    CommitDate?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum HealthStatusLevel {
    Information = 'Information',
    Warning = 'Warning',
    Failure = 'Failure'
}


/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ApiConfirmAccountRequest} [apiConfirmAccountRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmAccount: async (apiConfirmAccountRequest?: ApiConfirmAccountRequest, options: any = {}): Promise<RequestArgs> => {
    const localVarPath = `/api/confirm-account`;
    // use dummy base URL string because the URL constructor only accepts absolute URLs.
    const localVarUrlObj = new URL(localVarPath, 'https://example.com');
    let baseOptions;
    if (configuration) {
    baseOptions = configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;


            
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
            query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
            query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof apiConfirmAccountRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(apiConfirmAccountRequest !== undefined ? apiConfirmAccountRequest : {}) : (apiConfirmAccountRequest || "");

            return {
            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
            options: localVarRequestOptions,
            };
            },
        /**
         * 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateToken: async (body?: string, options: any = {}): Promise<RequestArgs> => {
    const localVarPath = `/api/validate-token`;
    // use dummy base URL string because the URL constructor only accepts absolute URLs.
    const localVarUrlObj = new URL(localVarPath, 'https://example.com');
    let baseOptions;
    if (configuration) {
    baseOptions = configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;


            
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
            query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
            query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
            options: localVarRequestOptions,
            };
            },
            }
            };

            /**
            * AccountApi - functional programming interface
            * @export
            */
            export const AccountApiFp = function(configuration?: Configuration) {
            return {
            /**
            * 
            * @param {ApiConfirmAccountRequest} [apiConfirmAccountRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async confirmAccount(apiConfirmAccountRequest?: ApiConfirmAccountRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string)=>
                AxiosPromise<void>
                    > {
                    const localVarAxiosArgs = await AccountApiAxiosParamCreator(configuration).confirmAccount(apiConfirmAccountRequest, options);
                    return (axios: AxiosInstance = EdvalStaticAxios, basePath: string = BASE_PATH) => {
                    const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                    return axios.request(axiosRequestArgs);
                    };
                    },
            /**
            * 
            * @param {string} [body] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async validateToken(body?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string)=>
                AxiosPromise<string>
                    > {
                    const localVarAxiosArgs = await AccountApiAxiosParamCreator(configuration).validateToken(body, options);
                    return (axios: AxiosInstance = EdvalStaticAxios, basePath: string = BASE_PATH) => {
                    const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                    return axios.request(axiosRequestArgs);
                    };
                    },
                    }
                    };

                    /**
                    * AccountApi - factory interface
                    * @export
                    */
                    export const AccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
                    return {
                    /**
                    * 
                    * @param {ApiConfirmAccountRequest} [apiConfirmAccountRequest] 
                    * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmAccount(apiConfirmAccountRequest?: ApiConfirmAccountRequest, options?: any): AxiosPromise<void> {
            return AccountApiFp(configuration).confirmAccount(apiConfirmAccountRequest, options).then((request) => request(axios, basePath));
        },
                    /**
                    * 
                    * @param {string} [body] 
                    * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateToken(body?: string, options?: any): AxiosPromise<string> {
            return AccountApiFp(configuration).validateToken(body, options).then((request) => request(axios, basePath));
        },
                        };
                        };

    /**
    * AccountApi - object-oriented interface
    * @export
    * @class AccountApi
    * @extends {BaseAPI}
    */
    export class AccountApi extends BaseAPI {
    /**
    * 
    * @param {ApiConfirmAccountRequest} [apiConfirmAccountRequest] 
    * @param {*} [options] Override http request option.
    * @throws {RequiredError}
    * @memberof AccountApi
    */
    public async confirmAccount(apiConfirmAccountRequest?: ApiConfirmAccountRequest, options?: any) {
    const request = await AccountApiFp(this.configuration).confirmAccount(apiConfirmAccountRequest, options);

    try {
      const response = (await request(this.axios, this.basePath)) as EdvalResponse<void>;
      response.IsManagedError = false;
      return response;
    } catch (error: any) {
      if (error.isAxiosError === true) {
        const axiosError = error as AxiosError;
        if (axiosError.response !== undefined && axiosError.response.headers.edvalmanagederror !== undefined) {
          const edvalResponse = axiosError.response as EdvalResponse<void>;
          const errorData = edvalResponse.data as EdvalManagedError;
          edvalResponse.ManagedError = errorData.ManagedError;
          edvalResponse.ManagedErrorData = JSON.parse(errorData.ManagedErrorDataBlob);
          edvalResponse.IsManagedError = true;
          // We don't want people accidentally accessing the regular data in the case of a business logic error error like this. Set it to undefined.
          edvalResponse.data = undefined;
          return edvalResponse;
        }
      }

      throw error;
    }
    }

    /**
    * 
    * @param {string} [body] 
    * @param {*} [options] Override http request option.
    * @throws {RequiredError}
    * @memberof AccountApi
    */
    public async validateToken(body?: string, options?: any) {
    const request = await AccountApiFp(this.configuration).validateToken(body, options);

    try {
      const response = (await request(this.axios, this.basePath)) as EdvalResponse<string>;
      response.IsManagedError = false;
      return response;
    } catch (error: any) {
      if (error.isAxiosError === true) {
        const axiosError = error as AxiosError;
        if (axiosError.response !== undefined && axiosError.response.headers.edvalmanagederror !== undefined) {
          const edvalResponse = axiosError.response as EdvalResponse<string>;
          const errorData = edvalResponse.data as EdvalManagedError;
          edvalResponse.ManagedError = errorData.ManagedError;
          edvalResponse.ManagedErrorData = JSON.parse(errorData.ManagedErrorDataBlob);
          edvalResponse.IsManagedError = true;
          // We don't want people accidentally accessing the regular data in the case of a business logic error error like this. Set it to undefined.
          edvalResponse.data = undefined;
          return edvalResponse;
        }
      }

      throw error;
    }
    }
    }


/**
 * FrontendSettings - axios parameter creator
 * @export
 */
export const FrontendSettingsAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserSettingsGet: async (options: any = {}): Promise<RequestArgs> => {
    const localVarPath = `/api/user/settings`;
    // use dummy base URL string because the URL constructor only accepts absolute URLs.
    const localVarUrlObj = new URL(localVarPath, 'https://example.com');
    let baseOptions;
    if (configuration) {
    baseOptions = configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;


            
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
            query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
            query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
            options: localVarRequestOptions,
            };
            },
            }
            };

            /**
            * FrontendSettings - functional programming interface
            * @export
            */
            export const FrontendSettingsFp = function(configuration?: Configuration) {
            return {
            /**
            * 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async apiUserSettingsGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string)=>
                AxiosPromise<void>
                    > {
                    const localVarAxiosArgs = await FrontendSettingsAxiosParamCreator(configuration).apiUserSettingsGet(options);
                    return (axios: AxiosInstance = EdvalStaticAxios, basePath: string = BASE_PATH) => {
                    const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                    return axios.request(axiosRequestArgs);
                    };
                    },
                    }
                    };

                    /**
                    * FrontendSettings - factory interface
                    * @export
                    */
                    export const FrontendSettingsFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
                    return {
                    /**
                    * 
                    * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserSettingsGet(options?: any): AxiosPromise<void> {
            return FrontendSettingsFp(configuration).apiUserSettingsGet(options).then((request) => request(axios, basePath));
        },
                        };
                        };

    /**
    * FrontendSettings - object-oriented interface
    * @export
    * @class FrontendSettings
    * @extends {BaseAPI}
    */
    export class FrontendSettings extends BaseAPI {
    /**
    * 
    * @param {*} [options] Override http request option.
    * @throws {RequiredError}
    * @memberof FrontendSettings
    */
    public async apiUserSettingsGet(options?: any) {
    const request = await FrontendSettingsFp(this.configuration).apiUserSettingsGet(options);

    try {
      const response = (await request(this.axios, this.basePath)) as EdvalResponse<void>;
      response.IsManagedError = false;
      return response;
    } catch (error: any) {
      if (error.isAxiosError === true) {
        const axiosError = error as AxiosError;
        if (axiosError.response !== undefined && axiosError.response.headers.edvalmanagederror !== undefined) {
          const edvalResponse = axiosError.response as EdvalResponse<void>;
          const errorData = edvalResponse.data as EdvalManagedError;
          edvalResponse.ManagedError = errorData.ManagedError;
          edvalResponse.ManagedErrorData = JSON.parse(errorData.ManagedErrorDataBlob);
          edvalResponse.IsManagedError = true;
          // We don't want people accidentally accessing the regular data in the case of a business logic error error like this. Set it to undefined.
          edvalResponse.data = undefined;
          return edvalResponse;
        }
      }

      throw error;
    }
    }
    }


/**
 * HealthApi - axios parameter creator
 * @export
 */
export const HealthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHealthLivenessGet: async (options: any = {}): Promise<RequestArgs> => {
    const localVarPath = `/api/health/liveness`;
    // use dummy base URL string because the URL constructor only accepts absolute URLs.
    const localVarUrlObj = new URL(localVarPath, 'https://example.com');
    let baseOptions;
    if (configuration) {
    baseOptions = configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;


            
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
            query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
            query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
            options: localVarRequestOptions,
            };
            },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHealthReadinessGet: async (options: any = {}): Promise<RequestArgs> => {
    const localVarPath = `/api/health/readiness`;
    // use dummy base URL string because the URL constructor only accepts absolute URLs.
    const localVarUrlObj = new URL(localVarPath, 'https://example.com');
    let baseOptions;
    if (configuration) {
    baseOptions = configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;


            
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
            query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
            query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
            options: localVarRequestOptions,
            };
            },
            }
            };

            /**
            * HealthApi - functional programming interface
            * @export
            */
            export const HealthApiFp = function(configuration?: Configuration) {
            return {
            /**
            * 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async apiHealthLivenessGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string)=>
                AxiosPromise<ApiHealthStatus>
                    > {
                    const localVarAxiosArgs = await HealthApiAxiosParamCreator(configuration).apiHealthLivenessGet(options);
                    return (axios: AxiosInstance = EdvalStaticAxios, basePath: string = BASE_PATH) => {
                    const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                    return axios.request(axiosRequestArgs);
                    };
                    },
            /**
            * 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async apiHealthReadinessGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string)=>
                AxiosPromise<ApiHealthStatus>
                    > {
                    const localVarAxiosArgs = await HealthApiAxiosParamCreator(configuration).apiHealthReadinessGet(options);
                    return (axios: AxiosInstance = EdvalStaticAxios, basePath: string = BASE_PATH) => {
                    const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                    return axios.request(axiosRequestArgs);
                    };
                    },
                    }
                    };

                    /**
                    * HealthApi - factory interface
                    * @export
                    */
                    export const HealthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
                    return {
                    /**
                    * 
                    * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHealthLivenessGet(options?: any): AxiosPromise<ApiHealthStatus> {
            return HealthApiFp(configuration).apiHealthLivenessGet(options).then((request) => request(axios, basePath));
        },
                    /**
                    * 
                    * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHealthReadinessGet(options?: any): AxiosPromise<ApiHealthStatus> {
            return HealthApiFp(configuration).apiHealthReadinessGet(options).then((request) => request(axios, basePath));
        },
                        };
                        };

    /**
    * HealthApi - object-oriented interface
    * @export
    * @class HealthApi
    * @extends {BaseAPI}
    */
    export class HealthApi extends BaseAPI {
    /**
    * 
    * @param {*} [options] Override http request option.
    * @throws {RequiredError}
    * @memberof HealthApi
    */
    public async apiHealthLivenessGet(options?: any) {
    const request = await HealthApiFp(this.configuration).apiHealthLivenessGet(options);

    try {
      const response = (await request(this.axios, this.basePath)) as EdvalResponse<ApiHealthStatus>;
      response.IsManagedError = false;
      return response;
    } catch (error: any) {
      if (error.isAxiosError === true) {
        const axiosError = error as AxiosError;
        if (axiosError.response !== undefined && axiosError.response.headers.edvalmanagederror !== undefined) {
          const edvalResponse = axiosError.response as EdvalResponse<ApiHealthStatus>;
          const errorData = edvalResponse.data as EdvalManagedError;
          edvalResponse.ManagedError = errorData.ManagedError;
          edvalResponse.ManagedErrorData = JSON.parse(errorData.ManagedErrorDataBlob);
          edvalResponse.IsManagedError = true;
          // We don't want people accidentally accessing the regular data in the case of a business logic error error like this. Set it to undefined.
          edvalResponse.data = undefined;
          return edvalResponse;
        }
      }

      throw error;
    }
    }

    /**
    * 
    * @param {*} [options] Override http request option.
    * @throws {RequiredError}
    * @memberof HealthApi
    */
    public async apiHealthReadinessGet(options?: any) {
    const request = await HealthApiFp(this.configuration).apiHealthReadinessGet(options);

    try {
      const response = (await request(this.axios, this.basePath)) as EdvalResponse<ApiHealthStatus>;
      response.IsManagedError = false;
      return response;
    } catch (error: any) {
      if (error.isAxiosError === true) {
        const axiosError = error as AxiosError;
        if (axiosError.response !== undefined && axiosError.response.headers.edvalmanagederror !== undefined) {
          const edvalResponse = axiosError.response as EdvalResponse<ApiHealthStatus>;
          const errorData = edvalResponse.data as EdvalManagedError;
          edvalResponse.ManagedError = errorData.ManagedError;
          edvalResponse.ManagedErrorData = JSON.parse(errorData.ManagedErrorDataBlob);
          edvalResponse.IsManagedError = true;
          // We don't want people accidentally accessing the regular data in the case of a business logic error error like this. Set it to undefined.
          edvalResponse.data = undefined;
          return edvalResponse;
        }
      }

      throw error;
    }
    }
    }


/**
 * ImportExportApi - axios parameter creator
 * @export
 */
export const ImportExportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _import: async (tenantId: string, body?: string, options: any = {}): Promise<RequestArgs> => {
    // verify required parameter 'tenantId' is not null or undefined
    if (tenantId === null || tenantId === undefined) {
    throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling _import.');
    }
    const localVarPath = `/api/import/{tenantId}`
    .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
    // use dummy base URL string because the URL constructor only accepts absolute URLs.
    const localVarUrlObj = new URL(localVarPath, 'https://example.com');
    let baseOptions;
    if (configuration) {
    baseOptions = configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;


            
            localVarHeaderParameter['Content-Type'] = 'application/zip';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
            query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
            query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
            options: localVarRequestOptions,
            };
            },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} academicYear 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExport: async (tenantId: string, academicYear: string, options: any = {}): Promise<RequestArgs> => {
    // verify required parameter 'tenantId' is not null or undefined
    if (tenantId === null || tenantId === undefined) {
    throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling getExport.');
    }
    // verify required parameter 'academicYear' is not null or undefined
    if (academicYear === null || academicYear === undefined) {
    throw new RequiredError('academicYear','Required parameter academicYear was null or undefined when calling getExport.');
    }
    const localVarPath = `/api/export/{tenantId}/{academicYear}`
    .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
    .replace(`{${"academicYear"}}`, encodeURIComponent(String(academicYear)));
    // use dummy base URL string because the URL constructor only accepts absolute URLs.
    const localVarUrlObj = new URL(localVarPath, 'https://example.com');
    let baseOptions;
    if (configuration) {
    baseOptions = configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;


            
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
            query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
            query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
            options: localVarRequestOptions,
            };
            },
            }
            };

            /**
            * ImportExportApi - functional programming interface
            * @export
            */
            export const ImportExportApiFp = function(configuration?: Configuration) {
            return {
            /**
            * 
            * @param {string} tenantId 
            * @param {string} [body] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async _import(tenantId: string, body?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string)=>
                AxiosPromise<void>
                    > {
                    const localVarAxiosArgs = await ImportExportApiAxiosParamCreator(configuration)._import(tenantId, body, options);
                    return (axios: AxiosInstance = EdvalStaticAxios, basePath: string = BASE_PATH) => {
                    const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                    return axios.request(axiosRequestArgs);
                    };
                    },
            /**
            * 
            * @param {string} tenantId 
            * @param {string} academicYear 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async getExport(tenantId: string, academicYear: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string)=>
                AxiosPromise<void>
                    > {
                    const localVarAxiosArgs = await ImportExportApiAxiosParamCreator(configuration).getExport(tenantId, academicYear, options);
                    return (axios: AxiosInstance = EdvalStaticAxios, basePath: string = BASE_PATH) => {
                    const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                    return axios.request(axiosRequestArgs);
                    };
                    },
                    }
                    };

                    /**
                    * ImportExportApi - factory interface
                    * @export
                    */
                    export const ImportExportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
                    return {
                    /**
                    * 
                    * @param {string} tenantId 
                    * @param {string} [body] 
                    * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _import(tenantId: string, body?: string, options?: any): AxiosPromise<void> {
            return ImportExportApiFp(configuration)._import(tenantId, body, options).then((request) => request(axios, basePath));
        },
                    /**
                    * 
                    * @param {string} tenantId 
                    * @param {string} academicYear 
                    * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExport(tenantId: string, academicYear: string, options?: any): AxiosPromise<void> {
            return ImportExportApiFp(configuration).getExport(tenantId, academicYear, options).then((request) => request(axios, basePath));
        },
                        };
                        };

    /**
    * ImportExportApi - object-oriented interface
    * @export
    * @class ImportExportApi
    * @extends {BaseAPI}
    */
    export class ImportExportApi extends BaseAPI {
    /**
    * 
    * @param {string} tenantId 
    * @param {string} [body] 
    * @param {*} [options] Override http request option.
    * @throws {RequiredError}
    * @memberof ImportExportApi
    */
    public async _import(tenantId: string, body?: string, options?: any) {
    const request = await ImportExportApiFp(this.configuration)._import(tenantId, body, options);

    try {
      const response = (await request(this.axios, this.basePath)) as EdvalResponse<void>;
      response.IsManagedError = false;
      return response;
    } catch (error: any) {
      if (error.isAxiosError === true) {
        const axiosError = error as AxiosError;
        if (axiosError.response !== undefined && axiosError.response.headers.edvalmanagederror !== undefined) {
          const edvalResponse = axiosError.response as EdvalResponse<void>;
          const errorData = edvalResponse.data as EdvalManagedError;
          edvalResponse.ManagedError = errorData.ManagedError;
          edvalResponse.ManagedErrorData = JSON.parse(errorData.ManagedErrorDataBlob);
          edvalResponse.IsManagedError = true;
          // We don't want people accidentally accessing the regular data in the case of a business logic error error like this. Set it to undefined.
          edvalResponse.data = undefined;
          return edvalResponse;
        }
      }

      throw error;
    }
    }

    /**
    * 
    * @param {string} tenantId 
    * @param {string} academicYear 
    * @param {*} [options] Override http request option.
    * @throws {RequiredError}
    * @memberof ImportExportApi
    */
    public async getExport(tenantId: string, academicYear: string, options?: any) {
    const request = await ImportExportApiFp(this.configuration).getExport(tenantId, academicYear, options);

    try {
      const response = (await request(this.axios, this.basePath)) as EdvalResponse<void>;
      response.IsManagedError = false;
      return response;
    } catch (error: any) {
      if (error.isAxiosError === true) {
        const axiosError = error as AxiosError;
        if (axiosError.response !== undefined && axiosError.response.headers.edvalmanagederror !== undefined) {
          const edvalResponse = axiosError.response as EdvalResponse<void>;
          const errorData = edvalResponse.data as EdvalManagedError;
          edvalResponse.ManagedError = errorData.ManagedError;
          edvalResponse.ManagedErrorData = JSON.parse(errorData.ManagedErrorDataBlob);
          edvalResponse.IsManagedError = true;
          // We don't want people accidentally accessing the regular data in the case of a business logic error error like this. Set it to undefined.
          edvalResponse.data = undefined;
          return edvalResponse;
        }
      }

      throw error;
    }
    }
    }


/**
 * JWTApi - axios parameter creator
 * @export
 */
export const JWTApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJwks: async (options: any = {}): Promise<RequestArgs> => {
    const localVarPath = `/api/.well-known/jwks.json`;
    // use dummy base URL string because the URL constructor only accepts absolute URLs.
    const localVarUrlObj = new URL(localVarPath, 'https://example.com');
    let baseOptions;
    if (configuration) {
    baseOptions = configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;


            
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
            query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
            query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
            options: localVarRequestOptions,
            };
            },
            }
            };

            /**
            * JWTApi - functional programming interface
            * @export
            */
            export const JWTApiFp = function(configuration?: Configuration) {
            return {
            /**
            * 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async getJwks(options?: any): Promise<(axios?: AxiosInstance, basePath?: string)=>
                AxiosPromise<ApiJWKs>
                    > {
                    const localVarAxiosArgs = await JWTApiAxiosParamCreator(configuration).getJwks(options);
                    return (axios: AxiosInstance = EdvalStaticAxios, basePath: string = BASE_PATH) => {
                    const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                    return axios.request(axiosRequestArgs);
                    };
                    },
                    }
                    };

                    /**
                    * JWTApi - factory interface
                    * @export
                    */
                    export const JWTApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
                    return {
                    /**
                    * 
                    * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJwks(options?: any): AxiosPromise<ApiJWKs> {
            return JWTApiFp(configuration).getJwks(options).then((request) => request(axios, basePath));
        },
                        };
                        };

    /**
    * JWTApi - object-oriented interface
    * @export
    * @class JWTApi
    * @extends {BaseAPI}
    */
    export class JWTApi extends BaseAPI {
    /**
    * 
    * @param {*} [options] Override http request option.
    * @throws {RequiredError}
    * @memberof JWTApi
    */
    public async getJwks(options?: any) {
    const request = await JWTApiFp(this.configuration).getJwks(options);

    try {
      const response = (await request(this.axios, this.basePath)) as EdvalResponse<ApiJWKs>;
      response.IsManagedError = false;
      return response;
    } catch (error: any) {
      if (error.isAxiosError === true) {
        const axiosError = error as AxiosError;
        if (axiosError.response !== undefined && axiosError.response.headers.edvalmanagederror !== undefined) {
          const edvalResponse = axiosError.response as EdvalResponse<ApiJWKs>;
          const errorData = edvalResponse.data as EdvalManagedError;
          edvalResponse.ManagedError = errorData.ManagedError;
          edvalResponse.ManagedErrorData = JSON.parse(errorData.ManagedErrorDataBlob);
          edvalResponse.IsManagedError = true;
          // We don't want people accidentally accessing the regular data in the case of a business logic error error like this. Set it to undefined.
          edvalResponse.data = undefined;
          return edvalResponse;
        }
      }

      throw error;
    }
    }
    }


/**
 * LoginApi - axios parameter creator
 * @export
 */
export const LoginApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ApiLoginRequest} [apiLoginRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (apiLoginRequest?: ApiLoginRequest, options: any = {}): Promise<RequestArgs> => {
    const localVarPath = `/api/login`;
    // use dummy base URL string because the URL constructor only accepts absolute URLs.
    const localVarUrlObj = new URL(localVarPath, 'https://example.com');
    let baseOptions;
    if (configuration) {
    baseOptions = configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;


            
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
            query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
            query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof apiLoginRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(apiLoginRequest !== undefined ? apiLoginRequest : {}) : (apiLoginRequest || "");

            return {
            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
            options: localVarRequestOptions,
            };
            },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshTokens: async (options: any = {}): Promise<RequestArgs> => {
    const localVarPath = `/api/refresh-tokens`;
    // use dummy base URL string because the URL constructor only accepts absolute URLs.
    const localVarUrlObj = new URL(localVarPath, 'https://example.com');
    let baseOptions;
    if (configuration) {
    baseOptions = configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;


            
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
            query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
            query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
            options: localVarRequestOptions,
            };
            },
        /**
         * 
         * @param {ApiCognitoAuthorizationCode} apiCognitoAuthorizationCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sSOLogin: async (apiCognitoAuthorizationCode: ApiCognitoAuthorizationCode, options: any = {}): Promise<RequestArgs> => {
    // verify required parameter 'apiCognitoAuthorizationCode' is not null or undefined
    if (apiCognitoAuthorizationCode === null || apiCognitoAuthorizationCode === undefined) {
    throw new RequiredError('apiCognitoAuthorizationCode','Required parameter apiCognitoAuthorizationCode was null or undefined when calling sSOLogin.');
    }
    const localVarPath = `/api/sso-login`;
    // use dummy base URL string because the URL constructor only accepts absolute URLs.
    const localVarUrlObj = new URL(localVarPath, 'https://example.com');
    let baseOptions;
    if (configuration) {
    baseOptions = configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;


            
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
            query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
            query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof apiCognitoAuthorizationCode !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(apiCognitoAuthorizationCode !== undefined ? apiCognitoAuthorizationCode : {}) : (apiCognitoAuthorizationCode || "");

            return {
            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
            options: localVarRequestOptions,
            };
            },
            }
            };

            /**
            * LoginApi - functional programming interface
            * @export
            */
            export const LoginApiFp = function(configuration?: Configuration) {
            return {
            /**
            * 
            * @param {ApiLoginRequest} [apiLoginRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async login(apiLoginRequest?: ApiLoginRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string)=>
                AxiosPromise<ApiLoginResponse>
                    > {
                    const localVarAxiosArgs = await LoginApiAxiosParamCreator(configuration).login(apiLoginRequest, options);
                    return (axios: AxiosInstance = EdvalStaticAxios, basePath: string = BASE_PATH) => {
                    const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                    return axios.request(axiosRequestArgs);
                    };
                    },
            /**
            * 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async refreshTokens(options?: any): Promise<(axios?: AxiosInstance, basePath?: string)=>
                AxiosPromise<void>
                    > {
                    const localVarAxiosArgs = await LoginApiAxiosParamCreator(configuration).refreshTokens(options);
                    return (axios: AxiosInstance = EdvalStaticAxios, basePath: string = BASE_PATH) => {
                    const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                    return axios.request(axiosRequestArgs);
                    };
                    },
            /**
            * 
            * @param {ApiCognitoAuthorizationCode} apiCognitoAuthorizationCode 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async sSOLogin(apiCognitoAuthorizationCode: ApiCognitoAuthorizationCode, options?: any): Promise<(axios?: AxiosInstance, basePath?: string)=>
                AxiosPromise<ApiLoginResponse>
                    > {
                    const localVarAxiosArgs = await LoginApiAxiosParamCreator(configuration).sSOLogin(apiCognitoAuthorizationCode, options);
                    return (axios: AxiosInstance = EdvalStaticAxios, basePath: string = BASE_PATH) => {
                    const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                    return axios.request(axiosRequestArgs);
                    };
                    },
                    }
                    };

                    /**
                    * LoginApi - factory interface
                    * @export
                    */
                    export const LoginApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
                    return {
                    /**
                    * 
                    * @param {ApiLoginRequest} [apiLoginRequest] 
                    * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(apiLoginRequest?: ApiLoginRequest, options?: any): AxiosPromise<ApiLoginResponse> {
            return LoginApiFp(configuration).login(apiLoginRequest, options).then((request) => request(axios, basePath));
        },
                    /**
                    * 
                    * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshTokens(options?: any): AxiosPromise<void> {
            return LoginApiFp(configuration).refreshTokens(options).then((request) => request(axios, basePath));
        },
                    /**
                    * 
                    * @param {ApiCognitoAuthorizationCode} apiCognitoAuthorizationCode 
                    * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sSOLogin(apiCognitoAuthorizationCode: ApiCognitoAuthorizationCode, options?: any): AxiosPromise<ApiLoginResponse> {
            return LoginApiFp(configuration).sSOLogin(apiCognitoAuthorizationCode, options).then((request) => request(axios, basePath));
        },
                        };
                        };

    /**
    * LoginApi - object-oriented interface
    * @export
    * @class LoginApi
    * @extends {BaseAPI}
    */
    export class LoginApi extends BaseAPI {
    /**
    * 
    * @param {ApiLoginRequest} [apiLoginRequest] 
    * @param {*} [options] Override http request option.
    * @throws {RequiredError}
    * @memberof LoginApi
    */
    public async login(apiLoginRequest?: ApiLoginRequest, options?: any) {
    const request = await LoginApiFp(this.configuration).login(apiLoginRequest, options);

    try {
      const response = (await request(this.axios, this.basePath)) as EdvalResponse<ApiLoginResponse>;
      response.IsManagedError = false;
      return response;
    } catch (error: any) {
      if (error.isAxiosError === true) {
        const axiosError = error as AxiosError;
        if (axiosError.response !== undefined && axiosError.response.headers.edvalmanagederror !== undefined) {
          const edvalResponse = axiosError.response as EdvalResponse<ApiLoginResponse>;
          const errorData = edvalResponse.data as EdvalManagedError;
          edvalResponse.ManagedError = errorData.ManagedError;
          edvalResponse.ManagedErrorData = JSON.parse(errorData.ManagedErrorDataBlob);
          edvalResponse.IsManagedError = true;
          // We don't want people accidentally accessing the regular data in the case of a business logic error error like this. Set it to undefined.
          edvalResponse.data = undefined;
          return edvalResponse;
        }
      }

      throw error;
    }
    }

    /**
    * 
    * @param {*} [options] Override http request option.
    * @throws {RequiredError}
    * @memberof LoginApi
    */
    public async refreshTokens(options?: any) {
    const request = await LoginApiFp(this.configuration).refreshTokens(options);

    try {
      const response = (await request(this.axios, this.basePath)) as EdvalResponse<void>;
      response.IsManagedError = false;
      return response;
    } catch (error: any) {
      if (error.isAxiosError === true) {
        const axiosError = error as AxiosError;
        if (axiosError.response !== undefined && axiosError.response.headers.edvalmanagederror !== undefined) {
          const edvalResponse = axiosError.response as EdvalResponse<void>;
          const errorData = edvalResponse.data as EdvalManagedError;
          edvalResponse.ManagedError = errorData.ManagedError;
          edvalResponse.ManagedErrorData = JSON.parse(errorData.ManagedErrorDataBlob);
          edvalResponse.IsManagedError = true;
          // We don't want people accidentally accessing the regular data in the case of a business logic error error like this. Set it to undefined.
          edvalResponse.data = undefined;
          return edvalResponse;
        }
      }

      throw error;
    }
    }

    /**
    * 
    * @param {ApiCognitoAuthorizationCode} apiCognitoAuthorizationCode 
    * @param {*} [options] Override http request option.
    * @throws {RequiredError}
    * @memberof LoginApi
    */
    public async sSOLogin(apiCognitoAuthorizationCode: ApiCognitoAuthorizationCode, options?: any) {
    const request = await LoginApiFp(this.configuration).sSOLogin(apiCognitoAuthorizationCode, options);

    try {
      const response = (await request(this.axios, this.basePath)) as EdvalResponse<ApiLoginResponse>;
      response.IsManagedError = false;
      return response;
    } catch (error: any) {
      if (error.isAxiosError === true) {
        const axiosError = error as AxiosError;
        if (axiosError.response !== undefined && axiosError.response.headers.edvalmanagederror !== undefined) {
          const edvalResponse = axiosError.response as EdvalResponse<ApiLoginResponse>;
          const errorData = edvalResponse.data as EdvalManagedError;
          edvalResponse.ManagedError = errorData.ManagedError;
          edvalResponse.ManagedErrorData = JSON.parse(errorData.ManagedErrorDataBlob);
          edvalResponse.IsManagedError = true;
          // We don't want people accidentally accessing the regular data in the case of a business logic error error like this. Set it to undefined.
          edvalResponse.data = undefined;
          return edvalResponse;
        }
      }

      throw error;
    }
    }
    }


/**
 * ProductsApi - axios parameter creator
 * @export
 */
export const ProductsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ApiProduct} [apiProduct] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProduct: async (apiProduct?: ApiProduct, options: any = {}): Promise<RequestArgs> => {
    const localVarPath = `/api/products`;
    // use dummy base URL string because the URL constructor only accepts absolute URLs.
    const localVarUrlObj = new URL(localVarPath, 'https://example.com');
    let baseOptions;
    if (configuration) {
    baseOptions = configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;


            
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
            query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
            query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof apiProduct !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(apiProduct !== undefined ? apiProduct : {}) : (apiProduct || "");

            return {
            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
            options: localVarRequestOptions,
            };
            },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProduct: async (id: string, options: any = {}): Promise<RequestArgs> => {
    // verify required parameter 'id' is not null or undefined
    if (id === null || id === undefined) {
    throw new RequiredError('id','Required parameter id was null or undefined when calling deleteProduct.');
    }
    const localVarPath = `/api/product/{id}`
    .replace(`{${"id"}}`, encodeURIComponent(String(id)));
    // use dummy base URL string because the URL constructor only accepts absolute URLs.
    const localVarUrlObj = new URL(localVarPath, 'https://example.com');
    let baseOptions;
    if (configuration) {
    baseOptions = configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;


            
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
            query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
            query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
            options: localVarRequestOptions,
            };
            },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProduct: async (id: string, options: any = {}): Promise<RequestArgs> => {
    // verify required parameter 'id' is not null or undefined
    if (id === null || id === undefined) {
    throw new RequiredError('id','Required parameter id was null or undefined when calling getProduct.');
    }
    const localVarPath = `/api/product/{id}`
    .replace(`{${"id"}}`, encodeURIComponent(String(id)));
    // use dummy base URL string because the URL constructor only accepts absolute URLs.
    const localVarUrlObj = new URL(localVarPath, 'https://example.com');
    let baseOptions;
    if (configuration) {
    baseOptions = configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;


            
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
            query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
            query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
            options: localVarRequestOptions,
            };
            },
        /**
         * 
         * @param {boolean} [onlyReleased] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProducts: async (onlyReleased?: boolean, options: any = {}): Promise<RequestArgs> => {
    const localVarPath = `/api/products`;
    // use dummy base URL string because the URL constructor only accepts absolute URLs.
    const localVarUrlObj = new URL(localVarPath, 'https://example.com');
    let baseOptions;
    if (configuration) {
    baseOptions = configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;

    if (onlyReleased !== undefined) {
    localVarQueryParameter['onlyReleased'] = onlyReleased;
    }


            
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
            query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
            query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
            options: localVarRequestOptions,
            };
            },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserProducts: async (options: any = {}): Promise<RequestArgs> => {
    const localVarPath = `/api/user/products`;
    // use dummy base URL string because the URL constructor only accepts absolute URLs.
    const localVarUrlObj = new URL(localVarPath, 'https://example.com');
    let baseOptions;
    if (configuration) {
    baseOptions = configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;


            
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
            query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
            query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
            options: localVarRequestOptions,
            };
            },
        /**
         * 
         * @param {string} id 
         * @param {ApiProduct} [apiProduct] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putProduct: async (id: string, apiProduct?: ApiProduct, options: any = {}): Promise<RequestArgs> => {
    // verify required parameter 'id' is not null or undefined
    if (id === null || id === undefined) {
    throw new RequiredError('id','Required parameter id was null or undefined when calling putProduct.');
    }
    const localVarPath = `/api/product/{id}`
    .replace(`{${"id"}}`, encodeURIComponent(String(id)));
    // use dummy base URL string because the URL constructor only accepts absolute URLs.
    const localVarUrlObj = new URL(localVarPath, 'https://example.com');
    let baseOptions;
    if (configuration) {
    baseOptions = configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;


            
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
            query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
            query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof apiProduct !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(apiProduct !== undefined ? apiProduct : {}) : (apiProduct || "");

            return {
            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
            options: localVarRequestOptions,
            };
            },
            }
            };

            /**
            * ProductsApi - functional programming interface
            * @export
            */
            export const ProductsApiFp = function(configuration?: Configuration) {
            return {
            /**
            * 
            * @param {ApiProduct} [apiProduct] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async createProduct(apiProduct?: ApiProduct, options?: any): Promise<(axios?: AxiosInstance, basePath?: string)=>
                AxiosPromise<ApiProduct>
                    > {
                    const localVarAxiosArgs = await ProductsApiAxiosParamCreator(configuration).createProduct(apiProduct, options);
                    return (axios: AxiosInstance = EdvalStaticAxios, basePath: string = BASE_PATH) => {
                    const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                    return axios.request(axiosRequestArgs);
                    };
                    },
            /**
            * 
            * @param {string} id 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async deleteProduct(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string)=>
                AxiosPromise<void>
                    > {
                    const localVarAxiosArgs = await ProductsApiAxiosParamCreator(configuration).deleteProduct(id, options);
                    return (axios: AxiosInstance = EdvalStaticAxios, basePath: string = BASE_PATH) => {
                    const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                    return axios.request(axiosRequestArgs);
                    };
                    },
            /**
            * 
            * @param {string} id 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async getProduct(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string)=>
                AxiosPromise<ApiProduct>
                    > {
                    const localVarAxiosArgs = await ProductsApiAxiosParamCreator(configuration).getProduct(id, options);
                    return (axios: AxiosInstance = EdvalStaticAxios, basePath: string = BASE_PATH) => {
                    const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                    return axios.request(axiosRequestArgs);
                    };
                    },
            /**
            * 
            * @param {boolean} [onlyReleased] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async getProducts(onlyReleased?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string)=>
                AxiosPromise<Array<ApiProduct>>
                    > {
                    const localVarAxiosArgs = await ProductsApiAxiosParamCreator(configuration).getProducts(onlyReleased, options);
                    return (axios: AxiosInstance = EdvalStaticAxios, basePath: string = BASE_PATH) => {
                    const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                    return axios.request(axiosRequestArgs);
                    };
                    },
            /**
            * 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async getUserProducts(options?: any): Promise<(axios?: AxiosInstance, basePath?: string)=>
                AxiosPromise<Array<ApiUserProduct>>
                    > {
                    const localVarAxiosArgs = await ProductsApiAxiosParamCreator(configuration).getUserProducts(options);
                    return (axios: AxiosInstance = EdvalStaticAxios, basePath: string = BASE_PATH) => {
                    const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                    return axios.request(axiosRequestArgs);
                    };
                    },
            /**
            * 
            * @param {string} id 
            * @param {ApiProduct} [apiProduct] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async putProduct(id: string, apiProduct?: ApiProduct, options?: any): Promise<(axios?: AxiosInstance, basePath?: string)=>
                AxiosPromise<void>
                    > {
                    const localVarAxiosArgs = await ProductsApiAxiosParamCreator(configuration).putProduct(id, apiProduct, options);
                    return (axios: AxiosInstance = EdvalStaticAxios, basePath: string = BASE_PATH) => {
                    const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                    return axios.request(axiosRequestArgs);
                    };
                    },
                    }
                    };

                    /**
                    * ProductsApi - factory interface
                    * @export
                    */
                    export const ProductsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
                    return {
                    /**
                    * 
                    * @param {ApiProduct} [apiProduct] 
                    * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProduct(apiProduct?: ApiProduct, options?: any): AxiosPromise<ApiProduct> {
            return ProductsApiFp(configuration).createProduct(apiProduct, options).then((request) => request(axios, basePath));
        },
                    /**
                    * 
                    * @param {string} id 
                    * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProduct(id: string, options?: any): AxiosPromise<void> {
            return ProductsApiFp(configuration).deleteProduct(id, options).then((request) => request(axios, basePath));
        },
                    /**
                    * 
                    * @param {string} id 
                    * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProduct(id: string, options?: any): AxiosPromise<ApiProduct> {
            return ProductsApiFp(configuration).getProduct(id, options).then((request) => request(axios, basePath));
        },
                    /**
                    * 
                    * @param {boolean} [onlyReleased] 
                    * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProducts(onlyReleased?: boolean, options?: any): AxiosPromise<Array<ApiProduct>> {
            return ProductsApiFp(configuration).getProducts(onlyReleased, options).then((request) => request(axios, basePath));
        },
                    /**
                    * 
                    * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserProducts(options?: any): AxiosPromise<Array<ApiUserProduct>> {
            return ProductsApiFp(configuration).getUserProducts(options).then((request) => request(axios, basePath));
        },
                    /**
                    * 
                    * @param {string} id 
                    * @param {ApiProduct} [apiProduct] 
                    * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putProduct(id: string, apiProduct?: ApiProduct, options?: any): AxiosPromise<void> {
            return ProductsApiFp(configuration).putProduct(id, apiProduct, options).then((request) => request(axios, basePath));
        },
                        };
                        };

    /**
    * ProductsApi - object-oriented interface
    * @export
    * @class ProductsApi
    * @extends {BaseAPI}
    */
    export class ProductsApi extends BaseAPI {
    /**
    * 
    * @param {ApiProduct} [apiProduct] 
    * @param {*} [options] Override http request option.
    * @throws {RequiredError}
    * @memberof ProductsApi
    */
    public async createProduct(apiProduct?: ApiProduct, options?: any) {
    const request = await ProductsApiFp(this.configuration).createProduct(apiProduct, options);

    try {
      const response = (await request(this.axios, this.basePath)) as EdvalResponse<ApiProduct>;
      response.IsManagedError = false;
      return response;
    } catch (error: any) {
      if (error.isAxiosError === true) {
        const axiosError = error as AxiosError;
        if (axiosError.response !== undefined && axiosError.response.headers.edvalmanagederror !== undefined) {
          const edvalResponse = axiosError.response as EdvalResponse<ApiProduct>;
          const errorData = edvalResponse.data as EdvalManagedError;
          edvalResponse.ManagedError = errorData.ManagedError;
          edvalResponse.ManagedErrorData = JSON.parse(errorData.ManagedErrorDataBlob);
          edvalResponse.IsManagedError = true;
          // We don't want people accidentally accessing the regular data in the case of a business logic error error like this. Set it to undefined.
          edvalResponse.data = undefined;
          return edvalResponse;
        }
      }

      throw error;
    }
    }

    /**
    * 
    * @param {string} id 
    * @param {*} [options] Override http request option.
    * @throws {RequiredError}
    * @memberof ProductsApi
    */
    public async deleteProduct(id: string, options?: any) {
    const request = await ProductsApiFp(this.configuration).deleteProduct(id, options);

    try {
      const response = (await request(this.axios, this.basePath)) as EdvalResponse<void>;
      response.IsManagedError = false;
      return response;
    } catch (error: any) {
      if (error.isAxiosError === true) {
        const axiosError = error as AxiosError;
        if (axiosError.response !== undefined && axiosError.response.headers.edvalmanagederror !== undefined) {
          const edvalResponse = axiosError.response as EdvalResponse<void>;
          const errorData = edvalResponse.data as EdvalManagedError;
          edvalResponse.ManagedError = errorData.ManagedError;
          edvalResponse.ManagedErrorData = JSON.parse(errorData.ManagedErrorDataBlob);
          edvalResponse.IsManagedError = true;
          // We don't want people accidentally accessing the regular data in the case of a business logic error error like this. Set it to undefined.
          edvalResponse.data = undefined;
          return edvalResponse;
        }
      }

      throw error;
    }
    }

    /**
    * 
    * @param {string} id 
    * @param {*} [options] Override http request option.
    * @throws {RequiredError}
    * @memberof ProductsApi
    */
    public async getProduct(id: string, options?: any) {
    const request = await ProductsApiFp(this.configuration).getProduct(id, options);

    try {
      const response = (await request(this.axios, this.basePath)) as EdvalResponse<ApiProduct>;
      response.IsManagedError = false;
      return response;
    } catch (error: any) {
      if (error.isAxiosError === true) {
        const axiosError = error as AxiosError;
        if (axiosError.response !== undefined && axiosError.response.headers.edvalmanagederror !== undefined) {
          const edvalResponse = axiosError.response as EdvalResponse<ApiProduct>;
          const errorData = edvalResponse.data as EdvalManagedError;
          edvalResponse.ManagedError = errorData.ManagedError;
          edvalResponse.ManagedErrorData = JSON.parse(errorData.ManagedErrorDataBlob);
          edvalResponse.IsManagedError = true;
          // We don't want people accidentally accessing the regular data in the case of a business logic error error like this. Set it to undefined.
          edvalResponse.data = undefined;
          return edvalResponse;
        }
      }

      throw error;
    }
    }

    /**
    * 
    * @param {boolean} [onlyReleased] 
    * @param {*} [options] Override http request option.
    * @throws {RequiredError}
    * @memberof ProductsApi
    */
    public async getProducts(onlyReleased?: boolean, options?: any) {
    const request = await ProductsApiFp(this.configuration).getProducts(onlyReleased, options);

    try {
      const response = (await request(this.axios, this.basePath)) as EdvalResponse<Array<ApiProduct>>;
      response.IsManagedError = false;
      return response;
    } catch (error: any) {
      if (error.isAxiosError === true) {
        const axiosError = error as AxiosError;
        if (axiosError.response !== undefined && axiosError.response.headers.edvalmanagederror !== undefined) {
          const edvalResponse = axiosError.response as EdvalResponse<Array<ApiProduct>>;
          const errorData = edvalResponse.data as EdvalManagedError;
          edvalResponse.ManagedError = errorData.ManagedError;
          edvalResponse.ManagedErrorData = JSON.parse(errorData.ManagedErrorDataBlob);
          edvalResponse.IsManagedError = true;
          // We don't want people accidentally accessing the regular data in the case of a business logic error error like this. Set it to undefined.
          edvalResponse.data = undefined;
          return edvalResponse;
        }
      }

      throw error;
    }
    }

    /**
    * 
    * @param {*} [options] Override http request option.
    * @throws {RequiredError}
    * @memberof ProductsApi
    */
    public async getUserProducts(options?: any) {
    const request = await ProductsApiFp(this.configuration).getUserProducts(options);

    try {
      const response = (await request(this.axios, this.basePath)) as EdvalResponse<Array<ApiUserProduct>>;
      response.IsManagedError = false;
      return response;
    } catch (error: any) {
      if (error.isAxiosError === true) {
        const axiosError = error as AxiosError;
        if (axiosError.response !== undefined && axiosError.response.headers.edvalmanagederror !== undefined) {
          const edvalResponse = axiosError.response as EdvalResponse<Array<ApiUserProduct>>;
          const errorData = edvalResponse.data as EdvalManagedError;
          edvalResponse.ManagedError = errorData.ManagedError;
          edvalResponse.ManagedErrorData = JSON.parse(errorData.ManagedErrorDataBlob);
          edvalResponse.IsManagedError = true;
          // We don't want people accidentally accessing the regular data in the case of a business logic error error like this. Set it to undefined.
          edvalResponse.data = undefined;
          return edvalResponse;
        }
      }

      throw error;
    }
    }

    /**
    * 
    * @param {string} id 
    * @param {ApiProduct} [apiProduct] 
    * @param {*} [options] Override http request option.
    * @throws {RequiredError}
    * @memberof ProductsApi
    */
    public async putProduct(id: string, apiProduct?: ApiProduct, options?: any) {
    const request = await ProductsApiFp(this.configuration).putProduct(id, apiProduct, options);

    try {
      const response = (await request(this.axios, this.basePath)) as EdvalResponse<void>;
      response.IsManagedError = false;
      return response;
    } catch (error: any) {
      if (error.isAxiosError === true) {
        const axiosError = error as AxiosError;
        if (axiosError.response !== undefined && axiosError.response.headers.edvalmanagederror !== undefined) {
          const edvalResponse = axiosError.response as EdvalResponse<void>;
          const errorData = edvalResponse.data as EdvalManagedError;
          edvalResponse.ManagedError = errorData.ManagedError;
          edvalResponse.ManagedErrorData = JSON.parse(errorData.ManagedErrorDataBlob);
          edvalResponse.IsManagedError = true;
          // We don't want people accidentally accessing the regular data in the case of a business logic error error like this. Set it to undefined.
          edvalResponse.data = undefined;
          return edvalResponse;
        }
      }

      throw error;
    }
    }
    }


/**
 * ResetPasswordApi - axios parameter creator
 * @export
 */
export const ResetPasswordApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ApiStartResetPasswordRequest} [apiStartResetPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startResetPassword: async (apiStartResetPasswordRequest?: ApiStartResetPasswordRequest, options: any = {}): Promise<RequestArgs> => {
    const localVarPath = `/api/reset-password/start`;
    // use dummy base URL string because the URL constructor only accepts absolute URLs.
    const localVarUrlObj = new URL(localVarPath, 'https://example.com');
    let baseOptions;
    if (configuration) {
    baseOptions = configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;


            
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
            query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
            query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof apiStartResetPasswordRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(apiStartResetPasswordRequest !== undefined ? apiStartResetPasswordRequest : {}) : (apiStartResetPasswordRequest || "");

            return {
            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
            options: localVarRequestOptions,
            };
            },
        /**
         * 
         * @param {ApiResetPasswordRequest} [apiResetPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitNewPassword: async (apiResetPasswordRequest?: ApiResetPasswordRequest, options: any = {}): Promise<RequestArgs> => {
    const localVarPath = `/api/reset-password/submit-new-password`;
    // use dummy base URL string because the URL constructor only accepts absolute URLs.
    const localVarUrlObj = new URL(localVarPath, 'https://example.com');
    let baseOptions;
    if (configuration) {
    baseOptions = configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;


            
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
            query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
            query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof apiResetPasswordRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(apiResetPasswordRequest !== undefined ? apiResetPasswordRequest : {}) : (apiResetPasswordRequest || "");

            return {
            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
            options: localVarRequestOptions,
            };
            },
            }
            };

            /**
            * ResetPasswordApi - functional programming interface
            * @export
            */
            export const ResetPasswordApiFp = function(configuration?: Configuration) {
            return {
            /**
            * 
            * @param {ApiStartResetPasswordRequest} [apiStartResetPasswordRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async startResetPassword(apiStartResetPasswordRequest?: ApiStartResetPasswordRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string)=>
                AxiosPromise<void>
                    > {
                    const localVarAxiosArgs = await ResetPasswordApiAxiosParamCreator(configuration).startResetPassword(apiStartResetPasswordRequest, options);
                    return (axios: AxiosInstance = EdvalStaticAxios, basePath: string = BASE_PATH) => {
                    const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                    return axios.request(axiosRequestArgs);
                    };
                    },
            /**
            * 
            * @param {ApiResetPasswordRequest} [apiResetPasswordRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async submitNewPassword(apiResetPasswordRequest?: ApiResetPasswordRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string)=>
                AxiosPromise<void>
                    > {
                    const localVarAxiosArgs = await ResetPasswordApiAxiosParamCreator(configuration).submitNewPassword(apiResetPasswordRequest, options);
                    return (axios: AxiosInstance = EdvalStaticAxios, basePath: string = BASE_PATH) => {
                    const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                    return axios.request(axiosRequestArgs);
                    };
                    },
                    }
                    };

                    /**
                    * ResetPasswordApi - factory interface
                    * @export
                    */
                    export const ResetPasswordApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
                    return {
                    /**
                    * 
                    * @param {ApiStartResetPasswordRequest} [apiStartResetPasswordRequest] 
                    * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startResetPassword(apiStartResetPasswordRequest?: ApiStartResetPasswordRequest, options?: any): AxiosPromise<void> {
            return ResetPasswordApiFp(configuration).startResetPassword(apiStartResetPasswordRequest, options).then((request) => request(axios, basePath));
        },
                    /**
                    * 
                    * @param {ApiResetPasswordRequest} [apiResetPasswordRequest] 
                    * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitNewPassword(apiResetPasswordRequest?: ApiResetPasswordRequest, options?: any): AxiosPromise<void> {
            return ResetPasswordApiFp(configuration).submitNewPassword(apiResetPasswordRequest, options).then((request) => request(axios, basePath));
        },
                        };
                        };

    /**
    * ResetPasswordApi - object-oriented interface
    * @export
    * @class ResetPasswordApi
    * @extends {BaseAPI}
    */
    export class ResetPasswordApi extends BaseAPI {
    /**
    * 
    * @param {ApiStartResetPasswordRequest} [apiStartResetPasswordRequest] 
    * @param {*} [options] Override http request option.
    * @throws {RequiredError}
    * @memberof ResetPasswordApi
    */
    public async startResetPassword(apiStartResetPasswordRequest?: ApiStartResetPasswordRequest, options?: any) {
    const request = await ResetPasswordApiFp(this.configuration).startResetPassword(apiStartResetPasswordRequest, options);

    try {
      const response = (await request(this.axios, this.basePath)) as EdvalResponse<void>;
      response.IsManagedError = false;
      return response;
    } catch (error: any) {
      if (error.isAxiosError === true) {
        const axiosError = error as AxiosError;
        if (axiosError.response !== undefined && axiosError.response.headers.edvalmanagederror !== undefined) {
          const edvalResponse = axiosError.response as EdvalResponse<void>;
          const errorData = edvalResponse.data as EdvalManagedError;
          edvalResponse.ManagedError = errorData.ManagedError;
          edvalResponse.ManagedErrorData = JSON.parse(errorData.ManagedErrorDataBlob);
          edvalResponse.IsManagedError = true;
          // We don't want people accidentally accessing the regular data in the case of a business logic error error like this. Set it to undefined.
          edvalResponse.data = undefined;
          return edvalResponse;
        }
      }

      throw error;
    }
    }

    /**
    * 
    * @param {ApiResetPasswordRequest} [apiResetPasswordRequest] 
    * @param {*} [options] Override http request option.
    * @throws {RequiredError}
    * @memberof ResetPasswordApi
    */
    public async submitNewPassword(apiResetPasswordRequest?: ApiResetPasswordRequest, options?: any) {
    const request = await ResetPasswordApiFp(this.configuration).submitNewPassword(apiResetPasswordRequest, options);

    try {
      const response = (await request(this.axios, this.basePath)) as EdvalResponse<void>;
      response.IsManagedError = false;
      return response;
    } catch (error: any) {
      if (error.isAxiosError === true) {
        const axiosError = error as AxiosError;
        if (axiosError.response !== undefined && axiosError.response.headers.edvalmanagederror !== undefined) {
          const edvalResponse = axiosError.response as EdvalResponse<void>;
          const errorData = edvalResponse.data as EdvalManagedError;
          edvalResponse.ManagedError = errorData.ManagedError;
          edvalResponse.ManagedErrorData = JSON.parse(errorData.ManagedErrorDataBlob);
          edvalResponse.IsManagedError = true;
          // We don't want people accidentally accessing the regular data in the case of a business logic error error like this. Set it to undefined.
          edvalResponse.data = undefined;
          return edvalResponse;
        }
      }

      throw error;
    }
    }
    }


/**
 * SSOApi - axios parameter creator
 * @export
 */
export const SSOApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} subdomain 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSSORedirect: async (subdomain: string, options: any = {}): Promise<RequestArgs> => {
    // verify required parameter 'subdomain' is not null or undefined
    if (subdomain === null || subdomain === undefined) {
    throw new RequiredError('subdomain','Required parameter subdomain was null or undefined when calling getSSORedirect.');
    }
    const localVarPath = `/api/sso/{subdomain}`
    .replace(`{${"subdomain"}}`, encodeURIComponent(String(subdomain)));
    // use dummy base URL string because the URL constructor only accepts absolute URLs.
    const localVarUrlObj = new URL(localVarPath, 'https://example.com');
    let baseOptions;
    if (configuration) {
    baseOptions = configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;


            
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
            query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
            query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
            options: localVarRequestOptions,
            };
            },
            }
            };

            /**
            * SSOApi - functional programming interface
            * @export
            */
            export const SSOApiFp = function(configuration?: Configuration) {
            return {
            /**
            * 
            * @param {string} subdomain 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async getSSORedirect(subdomain: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string)=>
                AxiosPromise<ApiSSORedirect>
                    > {
                    const localVarAxiosArgs = await SSOApiAxiosParamCreator(configuration).getSSORedirect(subdomain, options);
                    return (axios: AxiosInstance = EdvalStaticAxios, basePath: string = BASE_PATH) => {
                    const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                    return axios.request(axiosRequestArgs);
                    };
                    },
                    }
                    };

                    /**
                    * SSOApi - factory interface
                    * @export
                    */
                    export const SSOApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
                    return {
                    /**
                    * 
                    * @param {string} subdomain 
                    * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSSORedirect(subdomain: string, options?: any): AxiosPromise<ApiSSORedirect> {
            return SSOApiFp(configuration).getSSORedirect(subdomain, options).then((request) => request(axios, basePath));
        },
                        };
                        };

    /**
    * SSOApi - object-oriented interface
    * @export
    * @class SSOApi
    * @extends {BaseAPI}
    */
    export class SSOApi extends BaseAPI {
    /**
    * 
    * @param {string} subdomain 
    * @param {*} [options] Override http request option.
    * @throws {RequiredError}
    * @memberof SSOApi
    */
    public async getSSORedirect(subdomain: string, options?: any) {
    const request = await SSOApiFp(this.configuration).getSSORedirect(subdomain, options);

    try {
      const response = (await request(this.axios, this.basePath)) as EdvalResponse<ApiSSORedirect>;
      response.IsManagedError = false;
      return response;
    } catch (error: any) {
      if (error.isAxiosError === true) {
        const axiosError = error as AxiosError;
        if (axiosError.response !== undefined && axiosError.response.headers.edvalmanagederror !== undefined) {
          const edvalResponse = axiosError.response as EdvalResponse<ApiSSORedirect>;
          const errorData = edvalResponse.data as EdvalManagedError;
          edvalResponse.ManagedError = errorData.ManagedError;
          edvalResponse.ManagedErrorData = JSON.parse(errorData.ManagedErrorDataBlob);
          edvalResponse.IsManagedError = true;
          // We don't want people accidentally accessing the regular data in the case of a business logic error error like this. Set it to undefined.
          edvalResponse.data = undefined;
          return edvalResponse;
        }
      }

      throw error;
    }
    }
    }


/**
 * SignOutApi - axios parameter creator
 * @export
 */
export const SignOutApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signOut: async (options: any = {}): Promise<RequestArgs> => {
    const localVarPath = `/api/sign-out`;
    // use dummy base URL string because the URL constructor only accepts absolute URLs.
    const localVarUrlObj = new URL(localVarPath, 'https://example.com');
    let baseOptions;
    if (configuration) {
    baseOptions = configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;


            
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
            query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
            query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
            options: localVarRequestOptions,
            };
            },
            }
            };

            /**
            * SignOutApi - functional programming interface
            * @export
            */
            export const SignOutApiFp = function(configuration?: Configuration) {
            return {
            /**
            * 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async signOut(options?: any): Promise<(axios?: AxiosInstance, basePath?: string)=>
                AxiosPromise<void>
                    > {
                    const localVarAxiosArgs = await SignOutApiAxiosParamCreator(configuration).signOut(options);
                    return (axios: AxiosInstance = EdvalStaticAxios, basePath: string = BASE_PATH) => {
                    const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                    return axios.request(axiosRequestArgs);
                    };
                    },
                    }
                    };

                    /**
                    * SignOutApi - factory interface
                    * @export
                    */
                    export const SignOutApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
                    return {
                    /**
                    * 
                    * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signOut(options?: any): AxiosPromise<void> {
            return SignOutApiFp(configuration).signOut(options).then((request) => request(axios, basePath));
        },
                        };
                        };

    /**
    * SignOutApi - object-oriented interface
    * @export
    * @class SignOutApi
    * @extends {BaseAPI}
    */
    export class SignOutApi extends BaseAPI {
    /**
    * 
    * @param {*} [options] Override http request option.
    * @throws {RequiredError}
    * @memberof SignOutApi
    */
    public async signOut(options?: any) {
    const request = await SignOutApiFp(this.configuration).signOut(options);

    try {
      const response = (await request(this.axios, this.basePath)) as EdvalResponse<void>;
      response.IsManagedError = false;
      return response;
    } catch (error: any) {
      if (error.isAxiosError === true) {
        const axiosError = error as AxiosError;
        if (axiosError.response !== undefined && axiosError.response.headers.edvalmanagederror !== undefined) {
          const edvalResponse = axiosError.response as EdvalResponse<void>;
          const errorData = edvalResponse.data as EdvalManagedError;
          edvalResponse.ManagedError = errorData.ManagedError;
          edvalResponse.ManagedErrorData = JSON.parse(errorData.ManagedErrorDataBlob);
          edvalResponse.IsManagedError = true;
          // We don't want people accidentally accessing the regular data in the case of a business logic error error like this. Set it to undefined.
          edvalResponse.data = undefined;
          return edvalResponse;
        }
      }

      throw error;
    }
    }
    }


/**
 * VersionApi - axios parameter creator
 * @export
 */
export const VersionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVersionGet: async (options: any = {}): Promise<RequestArgs> => {
    const localVarPath = `/api/version`;
    // use dummy base URL string because the URL constructor only accepts absolute URLs.
    const localVarUrlObj = new URL(localVarPath, 'https://example.com');
    let baseOptions;
    if (configuration) {
    baseOptions = configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;


            
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
            query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
            query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
            options: localVarRequestOptions,
            };
            },
            }
            };

            /**
            * VersionApi - functional programming interface
            * @export
            */
            export const VersionApiFp = function(configuration?: Configuration) {
            return {
            /**
            * 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async apiVersionGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string)=>
                AxiosPromise<GitVersionConfiguration>
                    > {
                    const localVarAxiosArgs = await VersionApiAxiosParamCreator(configuration).apiVersionGet(options);
                    return (axios: AxiosInstance = EdvalStaticAxios, basePath: string = BASE_PATH) => {
                    const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                    return axios.request(axiosRequestArgs);
                    };
                    },
                    }
                    };

                    /**
                    * VersionApi - factory interface
                    * @export
                    */
                    export const VersionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
                    return {
                    /**
                    * 
                    * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVersionGet(options?: any): AxiosPromise<GitVersionConfiguration> {
            return VersionApiFp(configuration).apiVersionGet(options).then((request) => request(axios, basePath));
        },
                        };
                        };

    /**
    * VersionApi - object-oriented interface
    * @export
    * @class VersionApi
    * @extends {BaseAPI}
    */
    export class VersionApi extends BaseAPI {
    /**
    * 
    * @param {*} [options] Override http request option.
    * @throws {RequiredError}
    * @memberof VersionApi
    */
    public async apiVersionGet(options?: any) {
    const request = await VersionApiFp(this.configuration).apiVersionGet(options);

    try {
      const response = (await request(this.axios, this.basePath)) as EdvalResponse<GitVersionConfiguration>;
      response.IsManagedError = false;
      return response;
    } catch (error: any) {
      if (error.isAxiosError === true) {
        const axiosError = error as AxiosError;
        if (axiosError.response !== undefined && axiosError.response.headers.edvalmanagederror !== undefined) {
          const edvalResponse = axiosError.response as EdvalResponse<GitVersionConfiguration>;
          const errorData = edvalResponse.data as EdvalManagedError;
          edvalResponse.ManagedError = errorData.ManagedError;
          edvalResponse.ManagedErrorData = JSON.parse(errorData.ManagedErrorDataBlob);
          edvalResponse.IsManagedError = true;
          // We don't want people accidentally accessing the regular data in the case of a business logic error error like this. Set it to undefined.
          edvalResponse.data = undefined;
          return edvalResponse;
        }
      }

      throw error;
    }
    }
    }



export interface EdvalManagedError {
  ManagedError: string;
  ManagedErrorDataBlob?: any;
}

export type EdvalResponse<T> = AxiosResponse<T> & {
  IsManagedError: boolean;
  ManagedError?: string;
  ManagedErrorData?: unknown;
};