import { SSOApiFactory } from "apis/typescript_api_client/api";
import config from "apis/config";
const { getSSORedirect } = SSOApiFactory(config, config.basePath);

export function getLoginSubdomain(): string | undefined {
  const domain = /:\/\/([^\/:]+)/
    .exec(window.location.href)?.[1]
    .split(".")
    .find(x => x !== "www");

  if (domain !== undefined && domain.length !== 0 && domain !== "login" && !domain.startsWith("localhost")) {
    return domain;
  }

  return undefined;
}

function trimStart(s: string, trimChars: string) {
  if (!s) {
    return s;
  }

  while (s.startsWith(trimChars)) {
    s = s.substring(trimChars.length);
  }

  return s;
}

/**
 *
 * @param subdomain
 * @returns true if the user has been redirected.
 */
export async function redirectToSSOForSubdomain(subdomain: string): Promise<boolean> {
  const response = await getSSORedirect(subdomain);
  if (
    response.data.TenantId !== undefined &&
    response.data.SSOEnabled === true &&
    response.data.SSORedirectUrl !== undefined &&
    response.data.SSORedirectUrl.length !== 0
  ) {
    // There is a tenant associated with the subdomain, SSO is enabled,
    // and we have a non-empty redirect URL, we want to redirect to the SSO URL
    window.location.assign(response.data.SSORedirectUrl);

    return true;
  } else if (!response.data.TenantId) {
    // redirect to root
    window.location.hostname = trimStart(window.location.hostname.replace(subdomain, ""), ".");

    return true;
  }

  return false;
}
