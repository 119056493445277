import { submitNewPassword } from "apis";
import { EdvalLoginPageFrame } from "pages/commonComponents";
import { BoldLink, LoginFailureText } from "pages/commonComponents/loginPagesCommonStyle";
import ResetPasswordForm from "pages/commonComponents/resetPasswordForm";
import { useEffect, useState } from "react";
import Spinner from "react-spinner-material";
import { tokenValidation } from "utils/validateToken";

export function ResetPasswordPage(): JSX.Element {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token") ?? "";

  const [isValidToken, setIsValidToken] = useState(false);
  const [errorDetails, setErrorDetails] = useState<string | null>("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const validateToken = async () => {
      const result = await tokenValidation(token);
      setIsValidToken(result.isValid);
      setErrorDetails(result.details);
      setIsLoading(false);
    };

    validateToken();
  }, []);

  return isLoading ? (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "inherit" }}>
      <Spinner style={{ margin: "auto" }} radius={150} color={"#515af2"} stroke={6} visible={true} />
    </div>
  ) : isValidToken ? (
    <ResetPasswordForm
      greeting="Reset your password"
      passwordLabel="New Password"
      confirmPasswordLabel="Confirm new password"
      buttonText="update"
      successMessage="Password update successful!"
      failMessage="Failed to update password."
      setPassword={(newPassword: string) => () => submitNewPassword({ token, newPassword })}
    />
  ) : (
    <EdvalLoginPageFrame greeting="Something went wrong">
      <LoginFailureText>{errorDetails}</LoginFailureText>
      <br />
      <BoldLink to="/">Back to sign in page</BoldLink>
    </EdvalLoginPageFrame>
  );
}
