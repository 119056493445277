import styled from "styled-components/macro";
import { Button as MuiButton } from "@material-ui/core";

export const Button = styled(MuiButton)`
  margin-top: 38px;
  box-shadow: none;
  width: 100%;
  height: 35px;
  border-radius: 4px;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 1.43px;

  &:disabled {
    background-color: rgba(255, 255, 255, 1);
    span {
      color: rgba(155, 155, 155, 1);
    }
    border: 1px solid rgba(155, 155, 155, 1);
  }

  & .MuiButton-label {
    color: #fff;
  }

  ${({ theme }) => ` 
  &:hover {
    background: ${theme.palette.primary.main};
  }
 `}
`;
