import { validateToken } from "apis/login";
import { handleApiError } from "./apiErrorHandling";

export async function tokenValidation(token: string): Promise<{ isValid: boolean; details: string | null }> {
  try {
    const { status, data } = await validateToken(token);
    const trimmedData = trimQuotes(data);
    if (status === 200) {
      return { isValid: true, details: null };
    } else {
      return { isValid: false, details: trimmedData };
    }
  } catch (e) {
    handleApiError(e, "Failed to validate token.");

    return { isValid: false, details: "Unable to validate token. Please check the link and try again." };
  }
}

function trimQuotes(input: string): string {
  if (input.startsWith('"') && input.endsWith('"')) {
    return input.slice(1, -1);
  }

  return input;
}
