import { Toolbar, Button } from "@material-ui/core";
import styled from "styled-components";

export const ProductListWrapper = styled.div`
  background-color: #f6f6f6;
  padding: 20px 32px 32px 32px;
  color: #44444d;
`;

export const Header = styled.h2`
  font-size: 25px;
  margin: 0;
  padding-bottom: 20px;
  font-family: "Arial";
  font-style: normal;
  font-weight: 700;
`;

export const EdvalToolbar = styled(Toolbar)`
  height: 64px;
  padding-left: 32px;
`;

export const ProductGrid = styled.div`
  display: grid;
  row-gap: 52px;
  column-gap: 82px;
  grid-template-columns: repeat(auto-fill, minmax(416px, 1fr));
`;

export const LogoDiv = styled.div`
  font-family: "Arial";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #6c717b;
  padding-left: 8px;
  margin-left: 8px;
  border-left: 1px solid #6c717b;
`;

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const SignOutButton = styled(Button)`
  background: #212937;
  border: 1px solid #212937;
  border-radius: 4px;
  width: 89px;
  height: 32px;
  float: right;
  color: white;
  margin-left: auto;
  font-family: "Arial";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  text-transform: none;
  margin-right: 32px;
`;
