import { useEffect, useState } from "react";
import { EdvalLoginPageFrame, EmailInputForm, EdvalSubmitButtonWithLoadingStatus } from "../commonComponents";
import {
  BoldLink,
  ErrorMessage,
  LargeBoldText,
  LoginFailureText,
} from "../commonComponents/loginPagesCommonStyle";

function LoginFailurePage(): JSX.Element {
  const [errorMessage, setErrorMessage] = useState<{ heading: string; helpText: string | undefined }>({
    heading: "",
    helpText: undefined,
  });

  const getErrorMessage = (): { heading: string; helpText: string | undefined } => {
    const urlParams = new URLSearchParams(window.location.search);
    const errorType = urlParams.get("errorType");
    const errorDescription = urlParams.get("errorDescription")?.replaceAll("+", " ");

    if (errorType === null) {
      return { heading: "An unknown error has occurred.", helpText: undefined };
    } else {
      switch (errorType) {
        case "user_disabled":
          return {
            heading: "User account is disabled.",
            helpText: "Contact your administrator to re-enable your account.",
          };
        case "user_does_not_exist":
          return {
            heading: "User account does not exist in this school.",
            helpText: "Contact your administrator to add this account to the school.",
          };
        case "invalid_request":
          return {
            heading: "Invalid request",
            helpText: errorDescription,
          };
        case "unauthorized_client":
          return {
            heading: "Unauthorized client",
            helpText: "Client does not have permission for this request.",
          };
        case "invalid_scope":
          return {
            heading: "Invalid scope",
            helpText: "The requested scope was unknown, malformed, or not valid.",
          };
        case "server_error":
          return {
            heading: "Server error",
            helpText: "An unexpected error has occurred in the server.",
          };
        case "no_auth":
          return {
            heading: "Missing authorization code",
            helpText: "No authorization code was received.",
          };
        default:
          return { heading: "An unknown error has occurred.", helpText: undefined };
      }
    }
  };

  useEffect(() => {
    setErrorMessage(getErrorMessage());
  }, []);

  return (
    <EdvalLoginPageFrame greeting="Login failed">
      <LoginFailureText>{errorMessage.heading}</LoginFailureText>
      {errorMessage.helpText ? <LoginFailureText>{errorMessage.helpText}</LoginFailureText> : <></>}
      <br />
      <BoldLink id="back-to-sign-in" to="/">
        Back to sign in page
      </BoldLink>
    </EdvalLoginPageFrame>
  );
}

export default LoginFailurePage;
