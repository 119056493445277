import { Wrapper, Background, Content, TesLogo, Greeting } from "./style";

type LoginPageFrameProps = {
  greeting: string;
  children: React.ReactNode;
};

function EdvalLoginPageFrame({ greeting, children }: LoginPageFrameProps): JSX.Element {
  return (
    <Background>
      <Wrapper>
        <Content>
          <TesLogo />
          <Greeting>{greeting}</Greeting>
          {children}
        </Content>
      </Wrapper>
    </Background>
  );
}

export default EdvalLoginPageFrame;
