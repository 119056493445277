import { LogoContainer, LogoDiv } from "./style";
import { ReactComponent as LogoSvg } from "assets/tes_logo.svg";

export default function Logo() {
  return (
    <LogoContainer>
      <LogoSvg />
      <LogoDiv>Timetable</LogoDiv>
    </LogoContainer>
  );
}
